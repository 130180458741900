import { Component, Injector, Type } from '@angular/core';
import { ServiceLocator } from '@app/app.module';
import { EventsService, ModalsService, StorageService, ToastService } from '@services/services.service';

@Component({
	selector: 'app-basic',
	template: ` <p>base works!</p> `,
	styleUrls: []
})
export class BasicComponent {
	protected eventsService: EventsService;
	protected storageService: StorageService;
	protected modalsService: ModalsService;
	protected toastService: ToastService;

	constructor() {
		const injector: Injector = ServiceLocator.injector;
		this.eventsService = injector.get<EventsService>(EventsService as Type<EventsService>);
		this.storageService = injector.get<StorageService>(StorageService as Type<StorageService>);
		this.modalsService = injector.get<ModalsService>(ModalsService as Type<ModalsService>);
		this.toastService = injector.get<ToastService>(ToastService as Type<ToastService>);
	}

	navigateHome() {
		window.location.href = '/';
	}

	getPath(type: string, item: any) {
		return [
			type,
			item.id,
			item.name
				.replace(/ /g, '-')
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
		];
	}

	getPathAsString(type: string, item: any) {
		return (
			type +
			'/' +
			item.id +
			'/' +
			item.name
				.replace(/ /g, '-')
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
		);
	}

	getPathTitleAsString(type: string, item: any) {
		return (
			type +
			'/' +
			item.id +
			'/' +
			item.title
				.replace(/ /g, '-')
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
		);
	}
}
