<h2 class="ob-title">Marcar Consulta</h2>

<div class="row appointment-container">
	<div class="col col-12 col-md-6">
		<div class="img-appointment" [style.background-image]="'url(assets/images/appointment.png)'"></div>
	</div>
	<div class="col col-12 col-md-6">
		<form class="form" (submit)="create()" [formGroup]="appointmentForm">
			<div class="row align-items-center">
				<!-- Name -->
				<div class="col-12 mt-3">
					<input type="text" placeholder="Nome" formControlName="name" />
				</div>

				<!-- Email -->
				<div class="col-12 mt-3">
					<input type="text" placeholder="Email" formControlName="email" />
				</div>

				<!-- Phone -->
				<div class="col-12 mt-3">
					<input type="text" placeholder="Telemóvel" formControlName="phone" />
				</div>

				<!-- Stores -->
				<div class="col-12 col-md-6 mt-3">
					<select (change)="changeStore($event)" aria-placeholder="Loja" formControlName="store_id">
						<option *ngFor="let store of stores" [value]="store.id">{{ store.name }}</option>
					</select>
				</div>

				<!-- Specialty -->
				<div class="col-12 col-md-6 mt-3">
					<select formControlName="specialty_id">
						<option value="null" disabled hidden>Especialidade</option>
						<option *ngFor="let specialty of specialties" [value]="specialty.id">{{ specialty.name }}</option>
					</select>
				</div>

				<!-- Date -->
				<div class="col-12 col-md-6 mt-3">
					<input (change)="validateDate()" placeholder="dd/mm/aaaa" type="date" min="{{ startDate | date : 'yyyy-MM-dd' }}" max="{{ endDate | date : 'yyyy-MM-dd' }}" formControlName="date" />
					<small *ngIf="!isDateOk" class="text-danger">Data fora do horario de serviço.</small>
				</div>

				<!-- Hour -->
				<div class="col-12 col-md-6 mt-3">
					<input (change)="validateTime()" placeholder="--:--" type="time" formControlName="time" />
					<small *ngIf="!isTimeOk" class="text-danger">Hora fora do horario de serviço.</small>
				</div>
			</div>

			<button type="submit" [disabled]="appointmentForm.invalid" class="btn mt-3 w-50 center btn-white">Marcar</button>
		</form>
	</div>
</div>
