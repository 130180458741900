import { Component } from '@angular/core';
import { BasicComponent } from '@pages/basic/basic.component';
import { CategoryService, MerchantService, SpecialtyService } from '@services/services.service';
import { Category, Merchant, Specialty } from '@models/models.model';
import { NavigationEnd, Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent extends BasicComponent {
	categories: Category[] = [];
	specialties: Specialty[] = [];
	merchant: Merchant;
	logo: string;

	constructor(private categoryService: CategoryService, private specialtyService: SpecialtyService, private merchantService: MerchantService, private router: Router) {
		super();
		this.loadCategories();
		this.loadSpecialties();
		this.loadMerchant();

		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});
	}

	loadCategories() {
		this.categoryService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.categories = resp.data;
				this.storageService.set('categories', this.categories);
			}
		});
	}

	loadSpecialties() {
		this.specialtyService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.specialties = resp.data;
				this.storageService.set('specialties', this.specialties);
			}
		});
	}

	loadMerchant() {
		this.merchantService.url().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.merchant = resp.data;
				this.logo = this.merchant.logo;
				this.storageService.set('merchant', this.merchant);
			}
		});
	}
}
