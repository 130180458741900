import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class SubscriptionService {
	controller: string = '/';
	constructor(private apiService: ApiService) {}

	create(data: any) {
		return this.apiService.request(this.controller + 'create');
	}

	specialty() {
		return this.apiService.request(this.controller + 'specialty');
	}

	count() {
		return this.apiService.request(this.controller + 'count');
	}
}
