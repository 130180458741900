import { Component, OnInit } from '@angular/core';
import { OrderService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { Order, User } from '@models/models.model';

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.scss']
})
export class OrdersComponent extends BasicComponent implements OnInit {
	user: User;
	orders: Order[] = [];

	constructor(private orderService: OrderService) {
		super();

		this.user = this.storageService.get('user');

		if (!this.user) {
			this.navigateHome();
		}
	}

	ngOnInit(): void {
		this.load();
	}

	load() {
		this.orderService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.orders = resp.data;
			}
		});
	}
}
