import { Component, OnInit } from '@angular/core';
import { FamilyService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-family',
	templateUrl: './family.component.html',
	styleUrls: ['./family.component.scss']
})
export class FamilyComponent extends BasicComponent implements OnInit {
	family: any;
	owner: any;
	transactions: any[] = [];
	isUpdate: boolean = false;
	isJoin: boolean = false;
	familyForm: FormGroup;
	joinForm: FormGroup;

	constructor(private formBuilder: FormBuilder, private familyService: FamilyService) {
		super();

		this.familyForm = this.formBuilder.group({
			name: [null, [Validators.required]]
		});

		this.joinForm = this.formBuilder.group({
			code: [null, [Validators.required]]
		});
	}

	ngOnInit(): void {
		this.load();
	}

	load() {
		this.familyService.view().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.family = resp.data;
				this.familyForm.controls['name'].setValue(this.family.name);
				this.owner = this.family.members.find((members: any) => members.owner === true);
				this.loadTransactions();
			}
		});
	}

	leave() {
		this.familyService.leave(this.family.id).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.family = null;
				this.owner = null;
			}
		});
	}

	showUpdate() {
		this.isUpdate = !this.isUpdate;
	}

	showJoin() {
		this.isJoin = !this.isJoin;
	}

	loadTransactions() {
		this.familyService.transactions().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.transactions = resp.data;
			}
		});
	}

	update() {
		this.familyService.update(this.familyForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.showUpdate();
				this.load();
			}
		});
	}

	create() {
		this.familyService.create(this.familyForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.showUpdate();
				this.load();
			}
		});
	}

	join() {
		this.familyService.join(this.joinForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.showJoin();
				this.load();
			}
		});
	}
}
