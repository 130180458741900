import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { FacebookLoginProvider, GoogleInitOptions, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { QRCodeModule } from 'angularx-qrcode';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

//Components
import { NavbarComponent } from '@components/navbar/navbar.component';
import { HomeComponent } from '@pages/home/home.component';
import { AboutComponent } from '@pages/about/about.component';
import { FavoritesComponent } from '@pages/favorites/favorites.component';
import { CartComponent } from '@pages/cart/cart.component';
import { OrdersComponent } from '@pages/orders/orders.component';
import { FooterComponent } from '@components/footer/footer.component';
import { BasicComponent } from '@pages/basic/basic.component';
import { LoginComponent } from '@pages/login/login.component';
import { SearchComponent } from '@pages/search/search.component';
import { ProductsComponent } from '@pages/products/products.component';
import { ProductComponent } from '@pages/product/product.component';
import { SpecialtyComponent } from '@pages/specialty/specialty.component';
import { InfoComponent } from '@pages/info/info.component';
import { ContainerProductsComponent } from '@components/container-products/container-products.component';
import { ProductViewComponent } from '@components/product-view/product-view.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { MenuProfileComponent } from '@components/menu-profile/menu-profile.component';
import { AddressesComponent } from '@pages/addresses/addresses.component';
import { PasswordComponent } from '@pages/password/password.component';
import { OrderComponent } from '@pages/order/order.component';
import { CouponsComponent } from '@pages/coupons/coupons.component';
import { SchedulesPipe } from '@pipes/schedules.pipe';
import { QuotePipe } from '@pipes/quote.pipe';
import { StripeModule } from 'stripe-angular';
import { environment } from '@env/environment';
import { AppointmentContainerComponent } from '@components/appointment-container/appointment-container.component';
import { ContactsComponent } from '@pages/contacts/contacts.component';
import { ContactContainerComponent } from '@components/contact-container/contact-container.component';
import { EventComponent } from '@pages/event/event.component';
import { EditUserComponent } from '@pages/edit-user/edit-user.component';
import { SocialLinksComponent } from '@components/social-links/social-links.component';
import { LoyaltyComponent } from '@pages/loyalty/loyalty.component';
import { FamilyComponent } from '@pages/family/family.component';
import { AboutItemComponent } from '@pages/about-item/about-item.component';
import { PromotionsComponent } from '@pages/promotions/promotions.component';
import { HighlightsComponent } from '@pages/highlights/highlights.component';

const googleLoginOptions: GoogleInitOptions = {
	oneTapEnabled: false // default is true
};

export class ServiceLocator {
	static injector: Injector;
}

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		HomeComponent,
		AboutComponent,
		FavoritesComponent,
		CartComponent,
		OrdersComponent,
		FooterComponent,
		BasicComponent,
		LoginComponent,
		SearchComponent,
		ProductsComponent,
		ProductComponent,
		SpecialtyComponent,
		InfoComponent,
		ContainerProductsComponent,
		ProductViewComponent,
		ProfileComponent,
		MenuProfileComponent,
		AddressesComponent,
		PasswordComponent,
		OrderComponent,
		CouponsComponent,
		SchedulesPipe,
		QuotePipe,
		AppointmentContainerComponent,
		ContactsComponent,
		ContactContainerComponent,
		EventComponent,
		EditUserComponent,
		SocialLinksComponent,
		LoyaltyComponent,
		FamilyComponent,
		AboutItemComponent,
		PromotionsComponent,
		HighlightsComponent
		// StripPaymentComponent
	],
	imports: [BrowserModule, AppRoutingModule, NgbModule, ReactiveFormsModule, HttpClientModule, SlickCarouselModule, ToastrModule.forRoot(), BrowserAnimationsModule, VgCoreModule, VgControlsModule, VgOverlayPlayModule, VgBufferingModule, StripeModule.forRoot(), SocialLoginModule, QRCodeModule, ShareButtonsModule, ShareIconsModule],
	providers: [
		HttpClient,
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: FacebookLoginProvider.PROVIDER_ID,
						provider: new FacebookLoginProvider(environment.facebookApiKey)
					},
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(environment.googleApiKey, googleLoginOptions)
					}
				]
			} as SocialAuthServiceConfig
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private injector: Injector) {
		ServiceLocator.injector = this.injector;
	}
}
