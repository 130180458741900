import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ABOUTS, About } from '@models/about.model';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-about-item',
	templateUrl: './about-item.component.html',
	styleUrls: ['./about-item.component.scss']
})
export class AboutItemComponent extends BasicComponent implements OnInit {
	abouts: About[] = ABOUTS;
	about: About | undefined;
	slideConfig: any = {
		autoplay: false,
		infinite: false,
		slidesToShow: 4,
		centerMode: false,
		variableWidth: false,
		dots: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 775,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	constructor(private activatedRoute: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe(async (params: any) => {
			if (params.id) {
				this.load(params.id);
			}
		});
	}

	load(id: any) {
		this.abouts.map((about) => {
			about.see = true;
			if (about.id == id) {
				this.about = about;
				about.see = false;
			}

			return about;
		});
	}
}
