import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class ContactService {
	controller: string = 'contact/';

	constructor(private apiService: ApiService) {}

	email(data: any) {
		return this.apiService.request(this.controller + 'email', data, 'merchant', true);
	}
}
