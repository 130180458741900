import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.scss']
})
export class InfoComponent extends BasicComponent {
	merchant: any;
	title: string;
	data: string;

	constructor(private activatedRoute: ActivatedRoute) {
		super();

		this.activatedRoute.params.subscribe(async (params: any) => {
			this.merchant = await this.storageService.get('merchant');

			switch (params.id) {
				case 'condicoes-de-utilizacao':
					this.title = 'Condições de Utilização';
					this.data = this.merchant['terms'];
					break;
				case 'cookies':
					this.title = 'Política de cookies';
					this.data = this.merchant['cookies'];
					break;
				case 'politica-de-devolucoes':
					this.title = 'Política de devoluções';
					this.data = this.merchant['devolution'];
					break;
				case 'ral':
					this.title = 'Resolução Alternativa de Litígios';
					this.data = this.merchant['ral'];
					break;
				default:
					this.title = 'Política de Privacidade';
					this.data = this.merchant['privacy'];
					break;
			}
		});
	}
}
