<h2 class="ob-title">Moradas</h2>

<div class="profile-container">
	<div class="row mb-5">
		<div class="col-12 col-md-4 my-2">
			<app-menu-profile></app-menu-profile>
		</div>
		<div class="col-12 col-md-8 my-2 profile-content">
			<form class="form" (submit)="create()" [formGroup]="addressForm">
				<h2>Adicionar Morada</h2>
				<div class="row">
					<!-- Address -->
					<div class="col-12 col-md-12 col-lg-8 mt-3">
						<p class="label">Morada</p>
						<input type="text" formControlName="address" />
					</div>

					<!-- PostCode -->
					<div class="col-12 col-md-12 col-lg-4 mt-3">
						<p class="label">Código Postal</p>
						<input type="text" formControlName="postcode" />
					</div>

					<!-- City -->
					<div class="col-12 col-md-12 col-lg-4 mt-3">
						<p class="label">Cidade</p>
						<input type="text" formControlName="city" />
					</div>

					<!-- Country -->
					<div class="col-12 col-md-12 col-lg-4 mt-3">
						<p class="label">País</p>
						<input type="text" formControlName="country" />
					</div>

					<!-- Type -->
					<div class="col-12 col-md-12 col-lg-4 mt-3">
						<p class="label">Tipo</p>
						<select formControlName="type">
							<option value="1">Faturação</option>
							<option value="2">Entrega</option>
						</select>
					</div>
				</div>

				<button type="submit" [disabled]="addressForm.invalid" class="btn center btn-primary mt-3">Guardar</button>
			</form>

			<h2 class="mt-5 mb-2">Moradas de Faturação</h2>
			<p *ngIf="fiscal_addresses.length == 0">Não tem nenhuma morada de faturação registada</p>
			<div class="content-order row align-items-center" *ngFor="let address of fiscal_addresses; let i = index">
				<div class="col-11">
					<p>
						{{ address.address }} <br />
						{{ address.postcode }}, {{ address.city }} <br />
						{{ address.country }}
					</p>
				</div>
				<div class="col-1">
					<p role="button" (click)="removeAddress(address.id)"><i class="fas fa-trash-alt fa-lg"></i></p>
				</div>
				<hr *ngIf="i != fiscal_addresses.length - 1" />
			</div>
			<h2 class="mt-5 mb-2">Moradas de Entrega</h2>
			<p *ngIf="commercial_addresses.length == 0">Não tem nenhuma morada de entrega registada</p>
			<div class="content-order row align-items-center" *ngFor="let address of commercial_addresses; let i = index">
				<div class="col-11">
					<p>
						{{ address.address }} <br />
						{{ address.postcode }}, {{ address.city }} <br />
						{{ address.country }}
					</p>
				</div>
				<div class="col-1">
					<p role="button" (click)="removeAddress(address.id)"><i class="fas fa-trash-alt fa-lg"></i></p>
				</div>
				<hr *ngIf="i != commercial_addresses.length - 1" />
			</div>
		</div>
	</div>
</div>
