<h2 class="ob-title">Resumo da Encomenda</h2>
<p *ngIf="!user" class="m-0 text-center">
	<small class="text-muted">(Para fazer checkout deve primeiro fazer login!)</small>
</p>

<div class="cart-container">
	<p *ngIf="!cart.products.length" class="text-center">
		<small class="text-muted">O carrinho está vazio!</small>
	</p>

	<div class="row align-items-center" *ngFor="let product of cart.products">
		<div class="col-2">
			<img class="img center" [src]="product.image" />
		</div>
		<div class="col-4">
			<p class="m-0">
				{{ product.name }}
			</p>
		</div>
		<div class="col-4">
			<p *ngIf="!product.discount" class="m-0">{{ product.price }}€</p>
			<p *ngIf="product.discount" class="m-0">
				<span class="discount-price">{{ product.original_price }}€</span> <strong> {{ product.price }}€</strong>
				<span *ngIf="product.discount" class="ms-3 m-0 text-center">
					<span class="box-dark"> -{{ product.discount }}% </span>
					<span class="box-ligth">Promoção</span>
				</span>
			</p>
		</div>
		<div class="col-1 form">
			<input type="number" (change)="changeQuant($event, product)" [value]="product.quantity" />
		</div>
		<div type="button" (click)="removeProduct(product)" class="col-1">
			<p class="m-0">
				<i class="fas fa-trash"></i>
			</p>
		</div>
	</div>

	<div *ngIf="cart.products.length" class="row align-items-center">
		<hr class="large" />
		<div class="col-6"></div>
		<div class="col-4">
			<p><b>Subtotal:</b></p>
			<p><b>Saldo Descontado:</b></p>
			<p><b>Desconto Cupão:</b></p>
			<p><b>Taxa de envio:</b></p>
			<p><b>Total:</b></p>
		</div>
		<div class="col-2">
			<p>
				<b>{{ cart.total }}€</b>
			</p>
			<p>
				<b>{{ checkoutForm.value.discount }}€</b>
			</p>
			<p>
				<b>{{ checkoutForm.value.discount_coupon }}€</b>
			</p>
			<p>
				<b>{{ checkoutForm.value.delivery }}€</b>
			</p>
			<p>
				<b>{{ total }}€</b>
			</p>
		</div>
		<hr />
	</div>
</div>

<div *ngIf="cart.products.length && user" class="checkout-container">
	<h2 class="ob-title">Checkout</h2>
	<form class="form" (submit)="create()" [formGroup]="checkoutForm">
		<div class="row align-items-top">
			<div class="col-4">
				<h3>Dados Pessoais</h3>

				<!-- Name -->
				<div class="mt-3">
					<p class="label">Nome</p>
					<input type="text" formControlName="name" />
				</div>

				<!-- Email -->
				<div class="mt-3">
					<p class="label">Email</p>
					<input type="text" formControlName="email" />
				</div>

				<!-- Nif -->
				<div class="mt-3">
					<p class="label">Nif</p>
					<input type="text" formControlName="nif" />
				</div>

				<!-- Delivery Type -->
				<h3 class="mt-5">Método de Entrega</h3>
				<div class="mt-2">
					<div class="form-check">
						<input class="form-check-input" type="radio" (change)="changeDeliveryType(1)" name="delivery_type" [checked]="checkoutForm.value.delivery_type == 1 ? true : false" />
						<label class="form-check-label">Envio para a morada</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="radio" (change)="changeDeliveryType(0)" name="delivery_type" [checked]="checkoutForm.value.delivery_type == 0 ? true : false" />
						<label class="form-check-label">Levantamento em loja</label>
					</div>
				</div>
			</div>

			<div class="col-4">
				<h3>Morada de Entrega</h3>

				<!-- Store -->
				<div *ngIf="checkoutForm.value.delivery_type == 0">
					<p class="label">Loja</p>
					<select formControlName="store_id">
						<option *ngFor="let store of stores" [value]="store.id">{{ store.name }}</option>
					</select>
				</div>

				<!-- Addresses -->
				<div *ngIf="checkoutForm.value.delivery_type == 1">
					<div *ngIf="!newAddressDelivery">
						<p class="label">Selecione uma Morada</p>
						<select (click)="changeDeliveryAddress($event)">
							<option *ngFor="let address of addresses_delivery" [value]="address.id">{{ address.address }}, {{ address.postcode }}, {{ address.city }} {{ address.country }}</option>
						</select>

						<button class="btn btn-primary my-2" (click)="changeNewAddress(true)">Nova Morada</button>
					</div>

					<div *ngIf="newAddressDelivery">
						<div class="mt-3">
							<p class="label">Morada</p>
							<input type="text" formControlName="address" />
						</div>

						<!-- PostCode -->
						<div class="mt-3">
							<p class="label">Código Postal</p>
							<input type="text" formControlName="postal" />
						</div>

						<!-- City -->
						<div class="mt-3">
							<p class="label">Cidade</p>
							<input type="text" formControlName="city" />
						</div>

						<!-- Country -->
						<div class="mt-3">
							<p class="label">País</p>
							<input type="text" formControlName="country" />
						</div>

						<button class="btn btn-primary my-2" (click)="changeNewAddress(false)">Cancelar</button>
					</div>
				</div>

				<!-- Address Fiscal -->
				<div class="form-check mt-3">
					<input class="form-check-input" (change)="selectFiscalAddress($event)" type="checkbox" />
					<label class="form-check-label">Adicionar morada de Faturação</label>
				</div>

				<div *ngIf="showFiscalAddress">
					<div *ngIf="!newAddressFiscal">
						<p class="label">Selecione uma Morada</p>
						<select (click)="changeFiscalAddress($event)">
							<option *ngFor="let address of addresses_fiscal" [value]="address.id">{{ address.address }}, {{ address.postcode }}, {{ address.city }} {{ address.country }}</option>
						</select>

						<button class="btn btn-primary my-2" (click)="changeNewFiscalAddress(true)">Nova Morada</button>
					</div>

					<div *ngIf="newAddressFiscal">
						<!-- Address -->
						<div class="mt-3">
							<p class="label">Morada</p>
							<input type="text" formControlName="address_fiscal" />
						</div>

						<!-- Postal Code -->
						<div class="mt-3">
							<p class="label">Código Postal</p>
							<input type="text" formControlName="postal_fiscal" />
						</div>

						<!-- City -->
						<div class="mt-3">
							<p class="label">Cidade</p>
							<input type="text" formControlName="city_fiscal" />
						</div>

						<!-- Country -->
						<div class="mt-3">
							<p class="label">País</p>
							<input type="text" formControlName="country_fiscal" />
						</div>

						<button class="btn btn-primary my-2" (click)="changeNewFiscalAddress(false)">Cancelar</button>
					</div>
				</div>
			</div>

			<div class="col-4">
				<h3>Método de Pagamento</h3>
				<div class="mt-4">
					<div class="form-check">
						<input class="form-check-input" type="radio" (change)="changePaymentType(1)" name="payment_type" [checked]="checkoutForm.value.payment_type == 1 ? true : false" />
						<label class="form-check-label">MBWAY</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="radio" (change)="changePaymentType(2)" name="payment_type" [checked]="checkoutForm.value.payment_type == 2 ? true : false" />
						<label class="form-check-label">Cartão de Crédito</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="radio" (change)="changePaymentType(3)" name="payment_type" [checked]="checkoutForm.value.payment_type == 3 ? true : false" />
						<label class="form-check-label">Referência Multibanco</label>
					</div>
				</div>

				<div *ngIf="checkoutForm.value.payment_type == 1" class="mt-3">
					<p class="label">Nº Telemóvel *</p>
					<input type="text" formControlName="mbway" />
				</div>

				<div class="mt-5" *ngIf="coupons.length > 0">
					<h3>Cupão</h3>

					<select (change)="changeCoupon($event)" formControlName="coupon_id">
						<option value="null" disabled hidden>Selecione um cupão</option>
						<option *ngFor="let coupon of coupons" [value]="coupon.id">{{ coupon.amount }}{{ coupon.amount_type == "%" ? coupon.amount_type : "€" }}</option>
					</select>
				</div>

				<div class="mt-5" *ngIf="card && card.value > 0">
					<h3>Cartão</h3>
					<p><b>O seu Saldo:</b> {{ card.value }}€</p>
					<p class="label">Saldo a Usar</p>
					<input type="number" (change)="changeDiscount()" formControlName="discount" />
				</div>
			</div>
		</div>

		<button class="btn btn-primary-outline my-5 me-1" (click)="resetCart()">Limpar</button>
		<button type="submit" [disabled]="checkoutForm.invalid" class="btn btn-primary my-5 fr">Finalizar</button>
	</form>
</div>
