import { Injectable } from '@angular/core';
import { Cart } from '@models/models.model';
import { StorageService } from '@services/storage.service';
import { ToastService } from '@services/toast.service';
import { EventsService } from '@services/events.service';

@Injectable({
	providedIn: 'root'
})
export class CartService {
	cart: Cart = new Cart();

	constructor(private storageService: StorageService, private toastService: ToastService, private eventsService: EventsService) {
		this.cart = this.storageService.get('cart') ?? new Cart();
	}

	addProduct(product: any, showMessage: boolean = true, quantity?: number) {
		let quant = quantity ?? product.quantity;
		let index = this.cart.products.findIndex((cartProduct: any) => cartProduct.product_id == product.product_id && cartProduct.grade_lens == null);

		if (!product.grade_lens && index != -1) {
			let oldProduct = this.cart.products[index];
			if (oldProduct.quantity != undefined) {
				product.quantity = quant + oldProduct.quantity;
				product.sub_total = product.quantity * product.price;
			}
			this.cart.products[index] = product;
		} else {
			product.quantity = quant;
			product.sub_total = product.quantity * product.price;
			this.cart.products.push(product);
		}

		this.cart.quant += quant;
		this.cart.discount += product.discount;
		this.cart.total += product.price * quant;
		this.storageService.set('cart', this.cart);
		this.eventsService.publish('update:cart', this.cart);
		if (showMessage) this.toastService.showSuccess('Adicionado com sucesso');
	}

	updateProduct(product: any, showMessage: boolean = true, quantity: number) {
		let index = this.cart.products.findIndex((cartProduct: any) => cartProduct.product_id == product.product_id);

		product.quantity = quantity;
		product.sub_total = quantity * product.price;

		if (index != -1) {
			this.cart.products[index] = product;
		} else {
			this.cart.products.push(product);
		}

		this.cart.quant = 0;
		this.cart.discount = 0;
		this.cart.total = 0;

		this.cart.products.forEach((product: any) => {
			this.cart.quant += product.quantity;
			this.cart.discount += product.discount * product.quantity;
			this.cart.total += product.sub_total;
		});

		this.storageService.set('cart', this.cart);
		this.eventsService.publish('update:cart', this.cart);
		if (showMessage) this.toastService.showSuccess('Adicionado com sucesso');
	}

	subtractProduct(product: any, quant: number = 1) {
		let index = this.cart.products.findIndex((cartProduct: any) => cartProduct.product_id == product.product_id);

		if (index != -1) {
			let oldProduct = this.cart.products[index];
			if (oldProduct.quantity != undefined && oldProduct.sub_total != undefined) {
				product.quantity = oldProduct.quantity - quant;
				product.sub_total = oldProduct.sub_total - product.price;
				this.cart.products[index] = product;
				this.cart.quant -= quant;
				this.cart.discount -= product.discount;
				this.cart.total -= product.price * quant;
			}
		}
		this.storageService.set('cart', this.cart);
	}

	removeProduct(product: any, showMessage: boolean = true) {
		let index = this.cart.products.findIndex((cartProduct: any) => cartProduct.product_id == product.product_id);
		let quant = this.cart.products[index].quantity ?? 0;

		this.cart.quant -= quant;
		this.cart.discount -= product.discount * quant;
		this.cart.total -= product.sub_total;

		this.cart.products.splice(index, 1);

		this.storageService.set('cart', this.cart);
		this.eventsService.publish('update:cart', this.cart);
		if (showMessage) this.toastService.showSuccess('Removido com sucesso');
	}

	reset() {
		this.storageService.set('cart', new Cart());
		this.eventsService.publish('update:cart', new Cart());
		return (this.cart = new Cart());
	}
}
