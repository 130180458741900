import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class SpecialtyService {
	controller: string = 'specialties/';

	constructor(private apiService: ApiService) {}

	all(stores?: boolean, specialists?: boolean) {
		let data: any = {};

		if (stores) {
			data['stores'] = true;
		}

		if (specialists) {
			data['specialists'] = true;
		}

		return this.apiService.request(this.controller + 'all', data, 'id');
	}

	one(id: number) {
		return this.apiService.request(this.controller + 'one', { id }, undefined, false);
	}
}
