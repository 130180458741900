import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class CollectionService {
	controller: string = 'collection/';

	constructor(private apiService: ApiService) {}

	all() {
		return this.apiService.request(this.controller + 'all', null, 'id');
	}

	one(id: number) {
		return this.apiService.request(this.controller + 'one', { id }, undefined, false, true);
	}
}
