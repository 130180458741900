import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasicComponent } from '@pages/basic/basic.component';
import { UserService } from '@services/user.service';

@Component({
	selector: 'app-menu-profile',
	templateUrl: './menu-profile.component.html',
	styleUrls: ['./menu-profile.component.scss']
})
export class MenuProfileComponent extends BasicComponent implements OnInit {
	activeRoute: string = '';
	links: any[] = [
		{ url: '/perfil', name: 'Meu Perfil' },
		{ url: '/encomendas', name: 'Encomendas' },
		{ url: '/moradas', name: 'Moradas' },
		{ url: '/familia', name: 'Família' },
		{ url: '/fidelizacao', name: 'Fidelização' },
		{ url: '/editar-perfil', name: 'Alterar Dados Pessoais' },
		{ url: '/password', name: 'Alterar Palavra-passe' }
	];

	constructor(private router: Router, private userService: UserService) {
		super();

		this.activeRoute = this.router.url;
	}

	ngOnInit(): void {}

	logout() {
		this.userService.logout().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.storageService.remove('user');
				this.navigateHome();
			}
		});
	}
}
