import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class FamilyService {
	controller: string = 'family/';

	constructor(private apiService: ApiService) {}

	view() {
		return this.apiService.request(this.controller + 'view', null, undefined, false, true);
	}

	leave(family_id: string) {
		return this.apiService.request(this.controller + 'leave', { family_id }, undefined, true, true);
	}

	update(data: any) {
		return this.apiService.request(this.controller + 'update', data, undefined, true, true);
	}

	create(data: any) {
		return this.apiService.request(this.controller + 'create', data, undefined, true, true);
	}

	join(data: any) {
		return this.apiService.request(this.controller + 'join', data, undefined, true, true);
	}

	transactions() {
		return this.apiService.request(this.controller + 'transactions', null, undefined, false, true);
	}
}
