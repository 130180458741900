<!-- Banners -->
<div class="banners-container">
	<ngb-carousel *ngIf="banners.length" [showNavigationArrows]="showNavigationArrows">
		<ng-template ngbSlide *ngFor="let banner of banners">
			<div class="picsum-img-wrapper">
				<img class="img" [style.background-image]="'url(' + banner.image + ')'" />
			</div>
		</ng-template>
	</ngb-carousel>
</div>

<!-- Highlights -->
<div class="highlights-container">
	<app-container-products *ngIf="highlights.length > 0" [title]="'Destaques'" [link]="'/destaques'" [seeAllProduct]="true" [products]="highlights"> </app-container-products>
</div>

<!-- Collections -->
<div class="collection-container">
	<div class="row align-items-center mt-5">
		<a [href]="getPathAsString('/colecao', collections[0])" *ngIf="collections[0]" class="col-12 col-md-6 mt-3" role="button">
			<div class="img-collection img-collection-0" [style.background-image]="'url(' + collections[0].image + ')'"></div>
		</a>
		<div class="col-12 col-md-6 mt-3">
			<a [href]="getPathAsString('/colecao', collections[1])" *ngIf="collections[1]" role="button">
				<div class="img-collection" [style.background-image]="'url(' + collections[1].image + ')'"></div>
			</a>
			<div class="row">
				<a [href]="getPathAsString('/colecao', collections[2])" *ngIf="collections[2]" class="col-12 col-md-6 mt-3" role="button">
					<div class="img-collection" [style.background-image]="'url(' + collections[2].image + ')'"></div>
				</a>
				<a [href]="getPathAsString('/colecao', collections[3])" *ngIf="collections[3]" class="col-12 col-md-6 mt-3" role="button">
					<div class="img-collection" [style.background-image]="'url(' + collections[3].image + ')'"></div>
				</a>
			</div>
		</div>
	</div>
</div>

<!-- Accessories -->
<div class="highlights-container">
	<app-container-products *ngIf="accessories.length > 0" [title]="'Acessórios'" [seeAllProduct]="true" category="683" categoryName="acessorios" [products]="accessories"> </app-container-products>
</div>

<!-- Appointment -->
<div class="appointment-container">
	<app-appointment-container></app-appointment-container>
</div>

<!-- News -->
<div class="news-container">
	<h2 class="ob-title">Novidades</h2>
	<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
		<div ngxSlickItem *ngFor="let n of news" class="slide">
			<a [href]="getPathTitleAsString('/novidade', n)" class="card">
				<img [src]="n.image_path" class="card-img-top" />
				<div class="card-body">
					<h5 class="card-title">{{ n.title }}</h5>
					<p>{{ n.subtitle }}</p>
				</div>
			</a>
		</div>
	</ngx-slick-carousel>
</div>
