import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { Address, User } from '@models/models.model';

@Component({
	selector: 'app-addresses',
	templateUrl: './addresses.component.html',
	styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent extends BasicComponent implements OnInit {
	user: User;
	addressForm: FormGroup;
	fiscal_addresses: Address[] = [];
	commercial_addresses: Address[] = [];

	constructor(private formBuilder: FormBuilder, private addressService: AddressService) {
		super();

		this.user = this.storageService.get('user');

		if (!this.user) {
			this.navigateHome();
		}

		this.addressForm = this.formBuilder.group({
			address: [null, [Validators.required]],
			postcode: [null, [Validators.required]],
			city: [null, [Validators.required]],
			country: [null, [Validators.required]],
			type: [1, [Validators.required]]
		});
	}

	ngOnInit(): void {
		this.load();
	}

	load() {
		this.fiscal_addresses = [];
		this.commercial_addresses = [];
		this.addressService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				resp.data.forEach((address: Address) => {
					if (address.type == 1) {
						this.fiscal_addresses.push(address);
					} else {
						this.commercial_addresses.push(address);
					}
				});
			}
		});
	}

	create() {
		this.addressService.create(this.addressForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.addressForm.reset();
				this.load();
				this.toastService.showSuccess('Guardado com sucesso');
			} else {
				this.toastService.showError('Erro ao guardar');
			}
		});
	}

	removeAddress(id: number) {
		this.addressService.delete(id).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.load();
				this.toastService.showSuccess('Removido com sucesso');
			} else {
				this.toastService.showError('Erro ao remover');
			}
		});
	}
}
