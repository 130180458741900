import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AddressService, CartService, OrderService, PaymentService, StoreService, UserService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { Address, Cart, Coupon, Merchant, Product, Store, User } from '@models/models.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss']
})
export class CartComponent extends BasicComponent implements OnInit {
	cart: Cart;
	user: User | null;
	merchant: Merchant;
	checkoutForm: FormGroup;
	addresses: Address[] = [];
	addresses_delivery: Address[] = [];
	addresses_fiscal: Address[] = [];
	stores: Store[] = [];
	coupons: Coupon[] = [];
	showNavigationArrows: boolean = false;
	newAddressDelivery: boolean = false;
	newAddressFiscal: boolean = false;
	selectedStore: any;
	selectedAddress: any;
	selectedAddressFiscal: any;
	selectedPayment: any;
	card: any;
	total: number = 0;
	showFiscalAddress: boolean = false;
	paymentHandler: any = null;

	constructor(private cartService: CartService, private cdr: ChangeDetectorRef, private formBuilder: FormBuilder, private addressService: AddressService, private storeService: StoreService, private orderService: OrderService, private paymentService: PaymentService, private userService: UserService) {
		super();

		this.merchant = this.storageService.get('merchant');
		if (!this.merchant) {
			this.navigateHome();
		}

		this.user = this.storageService.get('user');
		this.cart = this.storageService.get('cart');
		if (!this.cart) {
			this.navigateHome();
		}

		this.checkoutForm = this.formBuilder.group({
			delivery_type: [1, [Validators.required]],
			address: [null, [Validators.required]],
			postal: [null, [Validators.required]],
			city: [null, [Validators.required]],
			country: [null, [Validators.required]],
			name: [this.user?.name, [Validators.required]],
			email: [this.user?.email, [Validators.required, Validators.email]],
			nif: [this.user?.nif],
			address_fiscal: [null],
			postal_fiscal: [null],
			city_fiscal: [null],
			country_fiscal: [null],
			store_id: [null],
			payment_type: [null, [Validators.required]],
			mbway: [null, [Validators.minLength(9), Validators.maxLength(9)]],
			delivery: [this.merchant.delivery, [Validators.required]],
			discount: [0, [Validators.min(0), Validators.max(0)]],
			discount_coupon: [0],
			coupon_id: [null],
			is_strip_pay: [false],
			checkout_id: [null],
			cart: [this.cart.products, [Validators.required]]
		});

		this.updateTotal();

		this.eventsService.subscribe('update:cart', (cart) => {
			this.cart = cart;
		});
	}

	ngOnInit(): void {
		this.loadStores();
		this.loadAddresses();
		this.loadLoyaltyCard();
		this.invokeStripe();
	}

	/** Loads */
	loadStores() {
		this.storeService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.stores = resp.data;
			}
		});
	}

	loadAddresses() {
		this.addressService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.addresses = resp.data;
				this.addresses_delivery = [];
				this.addresses_fiscal = [];
				this.addresses.forEach((address: any) => {
					if (address.type == 2) {
						this.addresses_delivery.push(address);
					} else {
						this.addresses_fiscal.push(address);
					}
				});

				if (this.addresses_delivery.length) {
					this.selectedAddress = this.addresses_delivery[0];
					this.checkoutForm.controls['address'].setValue(this.addresses_delivery[0].address);
					this.checkoutForm.controls['postal'].setValue(this.addresses_delivery[0].postcode);
					this.checkoutForm.controls['city'].setValue(this.addresses_delivery[0].city);
					this.checkoutForm.controls['country'].setValue(this.addresses_delivery[0].country);
				}

				if (this.addresses_fiscal.length) {
					this.selectedAddressFiscal = this.addresses_fiscal[0];
					this.checkoutForm.controls['address_fiscal'].setValue(this.addresses_fiscal[0].address);
					this.checkoutForm.controls['postal_fiscal'].setValue(this.addresses_fiscal[0].postcode);
					this.checkoutForm.controls['city_fiscal'].setValue(this.addresses_fiscal[0].city);
					this.checkoutForm.controls['country_fiscal'].setValue(this.addresses_fiscal[0].country);
				}
			}
		});
	}

	loadLoyaltyCard() {
		this.userService.loyaltycards().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				if (resp.data.length > 0) {
					this.card = resp.data[0];
					this.loadCoupons(this.card.id);
					this.checkoutForm.addValidators(Validators.max(this.card.value > 0 ? this.card.value : 0));
				}
			}
		});
	}

	loadCoupons(id: number) {
		this.userService.viewcard(id).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.coupons = resp.data;
			}
		});
	}

	invokeStripe() {
		if (!window.document.getElementById('stripe-script')) {
			const script = window.document.createElement('script');
			script.id = 'stripe-script';
			script.type = 'text/javascript';
			script.src = 'https://checkout.stripe.com/checkout.js';
			script.onload = () => {
				this.paymentHandler = (<any>window).StripeCheckout.configure({
					key: environment.stripeKey,
					locale: 'pt',
					token: function (stripeToken: any) {}
				});
			};
			window.document.body.appendChild(script);
		}
	}

	/** Changes **/
	changeDeliveryType(value: any) {
		this.checkoutForm.controls['delivery_type'].setValue(value);
		this.checkoutForm.controls['store_id'].setValue(null);
		if (value == 0) {
			this.checkoutForm.controls['store_id'].addValidators(Validators.required);
			this.checkoutForm.controls['delivery'].setValue(0);
		} else {
			this.checkoutForm.controls['store_id'].removeValidators(Validators.required);
			this.checkoutForm.controls['delivery'].setValue(this.merchant.delivery);
		}
		this.checkoutForm.controls['store_id'].asyncValidator;
		this.updateTotal();
		this.cdr.detectChanges();
	}

	selectFiscalAddress(event: any) {
		this.showFiscalAddress = event.target.checked;

		if (this.showFiscalAddress) {
			this.checkoutForm.controls['address_fiscal'].addValidators(Validators.required);
			this.checkoutForm.controls['city_fiscal'].addValidators(Validators.required);
			this.checkoutForm.controls['postal_fiscal'].addValidators(Validators.required);
			this.checkoutForm.controls['country_fiscal'].addValidators(Validators.required);
		} else {
			this.checkoutForm.controls['address_fiscal'].removeValidators(Validators.required);
			this.checkoutForm.controls['city_fiscal'].removeValidators(Validators.required);
			this.checkoutForm.controls['postal_fiscal'].removeValidators(Validators.required);
			this.checkoutForm.controls['country_fiscal'].removeValidators(Validators.required);
		}

		this.checkoutForm.controls['address_fiscal'].setAsyncValidators;
		this.checkoutForm.controls['city_fiscal'].setAsyncValidators;
		this.checkoutForm.controls['postal_fiscal'].setAsyncValidators;
		this.checkoutForm.controls['country_fiscal'].setAsyncValidators;
	}

	changeDeliveryAddress(event: any) {
		this.selectedAddress = this.addresses.find((address: any) => address.id == event.target.value);
		if (this.selectedAddress) {
			this.checkoutForm.controls['address'].setValue(this.selectedAddress.address);
			this.checkoutForm.controls['city'].setValue(this.selectedAddress.city);
			this.checkoutForm.controls['postal'].setValue(this.selectedAddress.postcode);
			this.checkoutForm.controls['country'].setValue(this.selectedAddress.country);
		}
	}

	changeFiscalAddress(event: any) {
		this.selectedAddressFiscal = this.addresses.find((address: any) => address.id == event.target.value);
		if (this.selectedAddressFiscal) {
			this.checkoutForm.controls['address_fiscal'].setValue(this.selectedAddressFiscal.address);
			this.checkoutForm.controls['city_fiscal'].setValue(this.selectedAddressFiscal.city);
			this.checkoutForm.controls['postal_fiscal'].setValue(this.selectedAddressFiscal.postcode);
			this.checkoutForm.controls['country_fiscal'].setValue(this.selectedAddressFiscal.country);
		}
	}

	changeNewAddress(value: boolean) {
		this.newAddressDelivery = value;

		if (value) {
			this.checkoutForm.controls['address'].setValue(null);
			this.checkoutForm.controls['city'].setValue(null);
			this.checkoutForm.controls['postal'].setValue(null);
			this.checkoutForm.controls['country'].setValue(null);
		} else {
			this.checkoutForm.controls['address'].setValue(this.selectedAddress.address);
			this.checkoutForm.controls['city'].setValue(this.selectedAddress.city);
			this.checkoutForm.controls['postal'].setValue(this.selectedAddress.postcode);
			this.checkoutForm.controls['country'].setValue(this.selectedAddress.country);
		}
	}

	changeNewFiscalAddress(value: boolean) {
		this.newAddressFiscal = value;

		if (value) {
			this.checkoutForm.controls['address_fiscal'].setValue(null);
			this.checkoutForm.controls['city_fiscal'].setValue(null);
			this.checkoutForm.controls['postal_fiscal'].setValue(null);
			this.checkoutForm.controls['country_fiscal'].setValue(null);
		} else {
			this.checkoutForm.controls['address_fiscal'].setValue(this.selectedAddressFiscal.address);
			this.checkoutForm.controls['city_fiscal'].setValue(this.selectedAddressFiscal.city);
			this.checkoutForm.controls['postal_fiscal'].setValue(this.selectedAddressFiscal.postcode);
			this.checkoutForm.controls['country_fiscal'].setValue(this.selectedAddressFiscal.country);
		}
	}

	resetCart() {
		this.cart = this.cartService.reset();
	}

	changeCoupon(event: any) {
		let coupon = this.coupons.find((coupon: any) => coupon.id == event.target.value);
		let value_discount = Number(coupon?.amount);

		if (coupon?.amount_type != 'Value') {
			value_discount = this.cart.total * value_discount * 0.01;
		}

		this.checkoutForm.controls['discount_coupon'].setValue(value_discount);
		this.updateTotal();
	}

	changeQuant(event: any, product: any) {
		console.log(Number(event.target.value), event.target.value);
		this.cartService.updateProduct(product, false, Number(event.target.value));
		this.cart = this.cartService.cart;
		this.updateTotal();
	}

	removeProduct(product: Product) {
		this.cartService.removeProduct(product, false);
	}

	changeDiscount() {
		this.updateTotal();
	}

	changePaymentType(value: number) {
		this.checkoutForm.controls['payment_type'].setValue(value);
		this.checkoutForm.controls['mbway'].setValue(null);

		if (value == 1) {
			this.checkoutForm.controls['mbway'].addValidators(Validators.required);
			this.checkoutForm.controls['mbway'].setAsyncValidators;
		}
	}

	updateTotal() {
		this.total = this.cart.total - this.checkoutForm.value.discount - this.checkoutForm.value.discount_coupon + this.checkoutForm.value.delivery;
	}

	/** Checkout **/

	create() {
		if (this.checkoutForm.value.payment_type == 2) {
			if (this.merchant.payments.cc == 'stripe') {
				this.initializePayment(this.total);
			} else {
				this.toastService.showError('Erro ao criar pagamento');
			}
		} else {
			this.orderService.create(this.checkoutForm.value).subscribe((resp: any) => {
				if (resp.status == 'OK') {
					this.generatePayment(resp.data);
				}
			});
		}
	}

	initializePayment(amount: number) {
		const paymentHandler = (<any>window).StripeCheckout.configure({
			key: environment.stripeKey,
			locale: 'pt',
			token: (stripeToken: any) => {
				this.closeOrder(stripeToken.id);
			}
		});

		paymentHandler.open({
			name: 'Zona Óptica',
			amount: amount * 100,
			email: this.user?.email,
			country: 'PT',
			currency: 'eur',
			locale: 'pt'
		});
	}

	generatePayment(order: any) {
		let payment: any;

		switch (this.checkoutForm.value.payment_type) {
			case 1:
				payment = this.merchant.payments.mbway == 'sibs' ? this.paymentService.mbway_sibs(order.id) : this.paymentService.mbway_eupago(order.id);
				break;
			default:
				payment = this.merchant.payments.mb == 'sibs' ? this.paymentService.mb_sibs(order.id) : this.paymentService.mb_eupago(order.id);
				break;
		}

		payment.subscribe((resp: any) => {
			this.toastService.showError(resp.message);
			if (resp.status == 'OK') {
				this.cartService.reset();
				this.cart = new Cart();
				this.toastService.showSuccess(resp.message);
				this.navigateHome();
			}
		});
	}

	closeOrder(token: string) {
		this.checkoutForm.controls['is_strip_pay'].setValue(true);
		this.checkoutForm.controls['checkout_id'].setValue(token);
		this.orderService.create(this.checkoutForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.cartService.reset();
				this.cart = new Cart();
				this.toastService.showSuccess(resp.message);
				this.navigateHome();
			}
		});
	}

	/*** */

	/*changePayment(event: any) {
		switch (event.target.value) {
			case '1':
				this.selectedPayment = 'MBWAY';
				break;
			case '2':
				this.selectedPayment = 'MASTER VISA';
				break;
			default:
				this.selectedPayment = 'REFERÊNCIA MULTIBANCO';
				break;
		}
	}

	

	confirm() {
		if (!this.checkoutForm.value.name || !this.checkoutForm.value.email) {
			this.toastService.showError('Preencha um nome e email');
		} else if (!this.checkoutForm.controls['email'].valid) {
			this.toastService.showError('Preencha um email válido');
		} else if (!this.checkoutForm.value.payment_type) {
			this.toastService.showError('Selecione um metodo de pagamento');
		} else if (this.checkoutForm.value.payment_type == 1 && (!this.checkoutForm.value.mbway || this.checkoutForm.value.mbway.invalid)) {
			this.toastService.showError('Preencha o numero de telemóvel');
		} else {
			this.total = this.cart.total - this.checkoutForm.value.discount - this.checkoutForm.value.discount_coupon + this.checkoutForm.value.delivery;
			window.scrollTo(0, 0);
		}
	}

	changeNewAddress(value: boolean) {
		this.newAddressDelivery = value;
		this.resetDeliveryAddress();
	}

	changeNewAddressFiscal(value: boolean) {
		this.newAddressFiscal = value;
		this.resetDeliveryAddressFiscal();
	}

	resetDeliveryAddress() {
		this.checkoutForm.controls['address'].setValue(null);
		this.checkoutForm.controls['city'].setValue(null);
		this.checkoutForm.controls['postal'].setValue(null);
		this.checkoutForm.controls['country'].setValue(null);
		this.checkoutForm.controls['store_id'].setValue(null);
		this.selectedStore = null;
		this.selectedAddress = null;
	}

	resetDeliveryAddressFiscal() {
		this.checkoutForm.controls['address_fiscal'].setValue(null);
		this.checkoutForm.controls['city_fiscal'].setValue(null);
		this.checkoutForm.controls['postal_fiscal'].setValue(null);
		this.checkoutForm.controls['country_fiscal'].setValue(null);
		this.selectedAddressFiscal = null;
	}

	

	

	changeDelivery(event: any) {
		let delivery = event.target.value == 0 ? 0 : this.merchant.delivery;
		this.checkoutForm.controls['delivery'].setValue(delivery);
		this.resetDeliveryAddress();
	}*/
}
