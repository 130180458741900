import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class CategoryService {
	controller: string = 'category/';

	constructor(private apiService: ApiService) {}

	all() {
		return this.apiService.request(this.controller + 'all', null, 'id');
	}

	subcategories(id: string) {
		return this.apiService.request(this.controller + 'subcategory', { id });
	}

	one(id: number) {
		return this.apiService.request(this.controller + 'one', { id });
	}
}
