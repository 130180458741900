import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class BannerService {
	controller: string = 'banner/';

	constructor(private apiService: ApiService) {}

	all() {
		return this.apiService.request(this.controller + 'all', null, 'id');
	}
}
