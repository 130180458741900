import { Component, OnInit } from '@angular/core';
import { Product, Brand, Category, PRICES } from '@models/models.model';
import { CategoryService, CollectionService, ProductService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandService } from '@app/services/brand.service';

@Component({
	selector: 'app-promotions',
	templateUrl: './promotions.component.html',
	styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent extends BasicComponent implements OnInit {
	page: number = 0;
	limit: number = 20;
	categoryId: any = null;
	collectionId: any = null;
	categories: Category[] = [];
	subcategories: any[] = [];
	brands: Brand[] = [];
	prices: any[] = PRICES;
	products: Product[] = [];
	selectedCategories: number[] = [];
	selectedSubcategories: number[] = [];
	selectedBrands: number[] = [];
	selectedPrices: string[] = [];
	image: string = 'assets/images/promos.png';
	title: string = 'Promoções';
	col: number = 3;
	isCollapsedCategories: boolean = false;
	isCollapsedSubcategories: boolean = false;
	isCollapsedBrands: boolean = false;
	isCollapsedPrices: boolean = false;
	verifyProducts: boolean = false;
	loading = false;

	constructor(private productService: ProductService, private activatedRoute: ActivatedRoute, private brandService: BrandService, private categoryService: CategoryService, private collectionService: CollectionService, private router: Router) {
		super();
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe(async (params: any) => {
			this.image = 'assets/images/promos.png';
			this.title = 'Promoções';
			this.categoryId = null;
			this.selectedSubcategories = [];
			this.selectedBrands = [];
			this.selectedPrices = [];

			if (params.category) {
				this.categoryId = await params.category;
			}

			if (params.subcategory) {
				await this.selectedSubcategories.push(Number(params.subcategory));
			}

			if (params.brand) {
				await this.selectedBrands.push(Number(params.brand));
			}

			if (params.collection) {
				this.collectionId = await params.collection;
			}

			await this.loadCategories();
			await this.loadSubcategories();
			await this.loadBrands();
			await this.loadProducts();
		});
	}

	/** Loads **/
	loadCategories() {
		this.categoryService.all().subscribe(async (resp: any) => {
			if (resp.status == 'OK') {
				this.categories = resp.data;

				this.categories.map((category: any) => {
          category.checked = false;
          if (category.id == this.categoryId) {
            category.checked = true;
            this.image = category.image;
            this.title = category.name;
          }
        });
			}
		});
	}

	loadSubcategories() {
		this.categoryService.subcategories(this.categoryId).subscribe(async (resp: any) => {
			if (resp.status == 'OK') {
				this.subcategories = await resp.data;

				this.subcategories.map((subcategory: any) => {
          subcategory.checked = false;
          if (this.selectedSubcategories.includes(subcategory.id)) {
            subcategory.checked = true;
          }
        });
			}
		});
	}

	loadBrands() {
		this.brandService.all(this.categoryId).subscribe(async (resp: any) => {
			if (resp.status == 'OK') {
				this.brands = await resp.data;

				this.brands.map((brand: any) => {
          brand.checked = false;
          if (this.selectedBrands.includes(brand.id)) {
            brand.checked = true;
            this.image = brand.image;
            this.title = brand.name;
          }
        });
			}
		});
	}

	async loadProducts(clear: boolean = true) {
		this.loading = true;

		if (this.page == 0) {
			this.products = [];
		}

		if (clear) {
			this.products = [];
			this.verifyProducts = false;
		}

		this.productService.filterPromotions(this.page, this.limit, this.categoryId, this.selectedSubcategories, this.selectedBrands, this.selectedPrices, this.collectionId).subscribe(async (resp: any) => {
			if (resp.status == 'OK') {
				if (this.page == 0) {
					this.products = await resp.data;
				} else {
					this.products = this.products.concat(resp.data);
				}

				if (resp.data.length == 0 || resp.data.length < this.limit) {
					this.page = -1;
				}

				this.products.map((product: any) => {
					product.image2 = product.images ? product.images[1] : '';
				});

				if (!this.products.length) {
					this.verifyProducts = true;
				}
			} else {
				this.page = -1;
			}

			this.loading = false;
		});
	}

	seeMore() {
		if (this.page >= 0) {
			this.page++;

			this.loadProducts(false);
		}
	}

	/** Changes **/

	gridChange(columns: number) {
		this.col = columns;
	}

	async categoryChange(event: any) {
		this.categoryId = event.target.checked ? event.target.value : null;
		this.selectedCategories = [];
		this.selectedSubcategories = [];
		this.selectedBrands = [];
		this.page = 0;

		this.categories.map((category: any) => {
      category.checked = false;
      if (category.id == this.categoryId) {
        category.checked = true;
        this.image = category.image;
        this.title = category.name;
      }
    });

    this.loadSubcategories();
    this.loadBrands();
		await this.loadProducts();
	}

	async subcategoryChange(event?: any) {
		this.page = 0;
		this.selectedBrands = [];

		if (event.target.checked) {
			this.selectedSubcategories.push(event.target.value);
		} else {
			const index = this.selectedSubcategories.indexOf(event.target.value, 0);
			if (index > -1) {
				this.selectedSubcategories.splice(index, 1);
			}
		}

		this.subcategories.map((subcategory: any) => {
      subcategory.checked = false;
      if (this.selectedSubcategories.includes(subcategory.id)) {
        subcategory.checked = true;
      }
    });

		await this.loadProducts();
	}

	async brandChange(event?: any) {
		this.page = 0;

		if (event.target.checked) {
			this.selectedBrands.push(event.target.value);
		} else {
			const index = this.selectedBrands.indexOf(event.target.value, 0);
			if (index > -1) {
				this.selectedBrands.splice(index, 1);
			}
		}

		this.brands.map((brand: any) => {
      brand.checked = false;
      if (this.selectedBrands.includes(brand.id)) {
        brand.checked = true;
      }
    });

		await this.loadProducts();
	}

	async priceChange(event?: any) {
		if (event.target.checked) {
			this.selectedPrices.push(event.target.value);
		} else {
			const index = this.selectedPrices.indexOf(event.target.value, 0);
			if (index > -1) {
				this.selectedPrices.splice(index, 1);
			}
		}

		this.prices.map((price: any) => {
      price.checked = false;
      if (this.selectedPrices.includes(price.id)) {
        price.checked = true;
      }
    });

		await this.loadProducts();
	}
}
