import { Component, OnInit } from '@angular/core';
import { Banner, News } from '@models/models.model';
import { BannerService, CollectionService, ProductService, NewsService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BasicComponent implements OnInit {
	showNavigationArrows: boolean = true;
	banners: Banner[] = [];
	highlights: any[] = [];
	accessories: any[] = [];
	news: News[] = [];
	collections: any[] = [];

	slideConfig: any = {
		autoplay: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		centerMode: false,
		variableWidth: false,
		dots: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 775,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	constructor(private bannerService: BannerService, private productService: ProductService, private collectionService: CollectionService, private newsService: NewsService) {
		super();
	}

	ngOnInit(): void {
		this.loadBanners();
		this.loadCollections();
		this.loadHighlights();
		this.loadAccessories();
		this.loadNews();
	}

	loadBanners() {
		this.bannerService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.banners = resp.data;
			}
		});
	}

	loadHighlights() {
		this.productService.highlights().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.highlights = resp.data;
			}
		});
	}

	loadAccessories() {
		this.productService.accessories().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.accessories = resp.data;
			}
		});
	}

	loadNews() {
		this.newsService.all(6).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.news = resp.data;
			}
		});
	}

	loadCollections() {
		this.collectionService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.collections = resp.data;
			}
		});
	}
}
