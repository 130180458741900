<h2 class="ob-title">Alterar Palavra-Passe</h2>

<div class="profile-container">
	<div class="row mb-5">
		<div class="col-12 col-md-4 my-2">
			<app-menu-profile></app-menu-profile>
		</div>
		<div class="col-12 col-md-8 my-2 profile-content">
			<form class="form" (submit)="password()" [formGroup]="passwordForm">
				<h2>Alterar Palavra-Passe</h2>
				<div class="row">
					<!-- Password -->
					<div class="col-12 col-md-12 col-lg-6">
						<p class="label">Palavra-Passe</p>
						<input type="password" formControlName="password" />
					</div>

					<!-- Confirm -->
					<div class="col-12 col-md-12 col-lg-6">
						<p class="label">Confirmar Palavra-Passe</p>
						<input type="password" formControlName="confirm" />
					</div>
				</div>

				<button type="submit" [disabled]="passwordForm.invalid || passwordForm.value.password != passwordForm.value.confirm" class="btn center btn-primary mt-3">Guardar</button>
			</form>
		</div>
	</div>
</div>
