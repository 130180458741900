import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class NewsService {
	controller: string = 'news/';

	constructor(private apiService: ApiService) {}

	all(limit?: number) {
		return this.apiService.request(this.controller + 'all', { limit }, 'id');
	}

	one(id: string) {
		return this.apiService.request(this.controller + 'one', { id });
	}
}
