<h2 class="ob-title">Família</h2>

<div class="profile-container">
	<div class="row mb-5">
		<div class="col-12 col-md-4 my-2">
			<app-menu-profile></app-menu-profile>
		</div>
		<div class="col-12 col-md-8 my-2 profile-content">
			<div *ngIf="!family">
				<p>Não tem nenhuma família registada</p>

				<button *ngIf="!isUpdate" class="btn btn-primary mt-3 me-1" (click)="showUpdate()">Criar</button>
				<button *ngIf="!isJoin" class="btn btn-primary-outline mt-3" (click)="showJoin()">Juntar</button>
			</div>

			<div *ngIf="family && owner">
				<h2>Dados da Família</h2>

				<div class="row">
					<div class="col-12 col-md-6">
						<p>
							<b>Nome:</b> {{ family.name }} <br />
							<b>Código:</b> {{ family.code }} <br />
							<b>Responsável:</b> {{ owner.name }} <br />
							<b>Saldo:</b> {{ family.value }}€
						</p>

						<button *ngIf="!isUpdate && family.owner" class="btn btn-primary btn-block mt-3" (click)="showUpdate()">Editar</button>
						<button *ngIf="!isUpdate" class="btn btn-primary-outline btn-block mt-3" (click)="leave()">Sair</button>
					</div>
					<div class="col-12 col-md-6 text-center">
						<qrcode [qrdata]="family.code" [errorCorrectionLevel]="'M'"></qrcode>
						<p class="m-0">Ler QR para se juntar à família</p>
						<span>(Também podem faze-lo usando o código)</span>
					</div>
				</div>
			</div>

			<div *ngIf="isUpdate">
				<form class="form" (submit)="family ? update() : create()" [formGroup]="familyForm">
					<h2 *ngIf="family">Alterar Família</h2>
					<h2 *ngIf="!family">Criar Família</h2>
					<div class="row">
						<div class="col-12 col-md-12 col-lg-6">
							<p class="label">Nome</p>
							<input type="text" formControlName="name" />
						</div>
					</div>

					<button (click)="showUpdate()" class="btn btn-primary-outline mt-3 me-1">Canceler</button>
					<button type="submit" [disabled]="familyForm.invalid" class="btn btn-primary mt-3">Guardar</button>
				</form>
			</div>

			<div *ngIf="isJoin">
				<form class="form" (submit)="join()" [formGroup]="joinForm">
					<h2>Juntar a uma Família</h2>
					<div class="row">
						<div class="col-12 col-md-12 col-lg-6">
							<p class="label">Código</p>
							<input type="text" formControlName="code" />
						</div>
					</div>

					<button (click)="showJoin()" class="btn btn-primary-outline mt-3 me-1">Canceler</button>
					<button type="submit" [disabled]="joinForm.invalid" class="btn btn-primary mt-3">Guardar</button>
				</form>
			</div>

			<div *ngIf="family && owner">
				<h2 class="mt-3">Movimentos</h2>
				<div class="table-responsive">
					<table class="table table-striped">
						<thead>
							<tr>
								<th scope="col">
									<b>Membro</b>
								</th>
								<th scope="col">
									<b>Loja</b>
								</th>
								<th scope="col">
									<b>Movimento</b>
								</th>
								<th scope="col">
									<b>Valor</b>
								</th>
								<th scope="col">
									<b>Cashback</b>
								</th>
								<th scope="col">
									<b>Saldo</b>
								</th>
								<th scope="col">
									<b>Data </b>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let transaction of transactions">
								<td>{{ transaction.customer.name }}</td>
								<td>{{ transaction.store }}</td>
								<td>{{ transaction.transaction_doc_number }}</td>
								<td>{{ transaction.amount }}€</td>
								<td>{{ transaction.total_cashback }}€</td>
								<td>{{ transaction.balance }}€</td>
								<td>{{ transaction.date }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
