import { Component, OnInit } from '@angular/core';
import { Merchant } from '@models/models.model';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-social-links',
	templateUrl: './social-links.component.html',
	styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent extends BasicComponent implements OnInit {
	merchant: Merchant | undefined;

	constructor() {
		super();

		this.merchant = this.storageService.get('merchant');
	}

	ngOnInit(): void {}
}
