import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class TestimonialService {
	controller: string = 'testimonial/';
	constructor(private apiService: ApiService) {}

	all(id?: string) {

		return this.apiService.request(this.controller + 'all', null, 'id');
	}
}
