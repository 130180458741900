import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
	providedIn: 'root'
})
export class ModalsService {
	constructor(private modalService: NgbModal) {}

	openModal(page: any, fullscreen?: boolean, css?: string, data?: any) {
		const modal = this.modalService.open(page, { windowClass: css, fullscreen: fullscreen ? true : false });

		if (data) {
			modal.componentInstance.data = data;
		}
	}
}
