import { Component, OnInit } from '@angular/core';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent extends BasicComponent implements OnInit {
	merchant: any;

	constructor() {
		super();
	}

	async ngOnInit() {
		this.merchant = await this.storageService.get('merchant');
	}
}
