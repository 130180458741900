interface Product {
	id: number;
	promo_id: string;
	reference: string;
	ean?: string;
	name: string;
	description: string;
	description_short: string;
	price: number;
	discount: number;
	price_with_discount: number;
	is_subscription: boolean;
	price_subscription: number;
	has_grade_lens: number;
	image: string;
	image2: string;
	images?: string[];
	material: string;
	typology: string;
	color: string;
	measures: string;
	category_id: number;
	brand_id: number;
	stock: number;
	packs?: any[];
	curves?: any[];
	diameters?: any[];
	balls?: any[];
	cylindricals?: any[];
	axies?: any[];
	addictions?: any[];
	geometries?: any[];
	count_version: number;
	favorite: boolean;
	quant?: number | 0;
	sub_total?: number | 0;
  sell_online: boolean;
}

const PRICES: any[] = [
	{ id: '50:100', name: '50€ - 100€' },
	{ id: '100:200', name: '100€ - 200€' },
	{ id: '200:300', name: '200€ - 300€' },
	{ id: '300:400', name: '300€ - 400€' },
	{ id: '400:500', name: '400€ - 500€' }
];

export { Product, PRICES };
