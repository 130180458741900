import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	public data: any;
	private localStorageService: Storage;
	prefix: any;

	constructor() {
		this.localStorageService = window.localStorage;
		this.prefix = environment.merchantUrl;
	}

	get(key: string) {
		return JSON.parse(this.localStorageService.getItem(`${this.prefix}/${key}`) || 'null');
	}

	async set(key: string, value: any) {
		await this.localStorageService.setItem(`${this.prefix}/${key}`, JSON.stringify(value));
	}

	remove(key: string) {
		this.localStorageService.removeItem(`${this.prefix}/${key}`);
	}
}
