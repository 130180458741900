import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/services/user.service';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-loyalty',
	templateUrl: './loyalty.component.html',
	styleUrls: ['./loyalty.component.scss']
})
export class LoyaltyComponent extends BasicComponent implements OnInit {
	card: any;
	transactions: any[] = [];

	constructor(private userService: UserService) {
		super();
	}

	ngOnInit(): void {
		this.load();
	}

	load() {
		this.userService.loyaltycards().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				if (resp.data.length > 0) {
					this.card = resp.data[0];
					this.loadTransactions();
				}
			}
		});
	}

	loadTransactions() {
		this.userService.transactions().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.transactions = resp.data.transactions;
			}
		});
	}
}
