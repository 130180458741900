import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { User } from '@models/models.model';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss']
})
export class PasswordComponent extends BasicComponent implements OnInit {
	user: User;
	passwordForm: FormGroup;

	constructor(private formBuilder: FormBuilder, private userService: UserService) {
		super();

		this.user = this.storageService.get('user');

		if (!this.user) {
			this.navigateHome();
		}

		this.passwordForm = this.formBuilder.group({
			password: [null, [Validators.required, Validators.minLength(6)]],
			confirm: [null, [Validators.required]]
		});
	}

	ngOnInit(): void {}

	password() {
		this.userService.password(this.passwordForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.passwordForm.reset();
				this.toastService.showSuccess('Guardado com sucesso');
			} else {
				this.toastService.showError('Erro ao guardar');
			}
		});
	}
}
