import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { SocialAuthService, FacebookLoginProvider } from '@abacritt/angularx-social-login';

declare var gapi: any;

@Injectable({
	providedIn: 'root'
})
export class UserService {
	controller: string = 'customer/';

	constructor(private apiService: ApiService, private socialAuthService: SocialAuthService) {}

	login(data: any) {
		return this.apiService.request(this.controller + 'login', data, 'merchant_id', true);
	}

	recovery(data: any) {
		return this.apiService.request(this.controller + 'recovery', data, 'merchant_id', true);
	}

	create(data: any) {
		return this.apiService.request(this.controller + 'create', data, 'merchant_id', true);
	}

	edit(data: any) {
		return this.apiService.request(this.controller + 'edit', data, undefined, true, true);
	}

	password(data: any) {
		return this.apiService.request(this.controller + 'password', data, undefined, true, true);
	}

	favorites() {
		return this.apiService.request(this.controller + 'favorites', null, undefined, false, true);
	}

	logout() {
		return this.apiService.request(this.controller + 'logout', null, undefined, false, true);
	}

	viewcard(id: number) {
		return this.apiService.request(this.controller + 'viewcard', { id }, undefined, false, true);
	}

	transactions(limit: number = 20) {
		return this.apiService.request(this.controller + `loyalty-transactions?limit=${limit}`, null, undefined, false, true);
	}

	verifyEmail(email: string) {
		return this.apiService.request(this.controller + 'verifyemail', { email }, 'merchant_id', true, false);
	}

	loyaltycards() {
		return this.apiService.request(this.controller + 'loyaltycards', null, undefined, false, true);
	}

	loginFacebook() {
		return this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
	}
}
