import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class MerchantService {
	controller: string = 'merchant/';

	constructor(private apiService: ApiService) {}

	url() {
		return this.apiService.request(this.controller + 'url', { url: environment.merchantUrl });
	}
}
