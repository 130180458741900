<h2 class="ob-title">Encomendas</h2>

<div class="profile-container">
	<div class="row mb-5">
		<div class="col-12 col-md-4 my-2">
			<app-menu-profile></app-menu-profile>
		</div>
		<div class="col-12 col-md-8 my-2 profile-content">
			<p *ngIf="orders.length == 0">Não tem nenhuma encomenda registada</p>
			<a [href]="'/encomenda/' + order.id" class="content-order row align-items-center" role="button" *ngFor="let order of orders">
				<div class="col-6">
					<p>{{ order.name }}</p>
					<p>
						{{ order.date }} <span [ngClass]="order.status">{{ order.status }}</span>
					</p>
				</div>
				<div class="col-6">
					<p class="text-end">{{ order.total }}€</p>
					<p class="text-end">{{ order.payment_type }}</p>
				</div>
			</a>
		</div>
	</div>
</div>
