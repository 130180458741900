import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { User } from '@app/models/user.model';
import { StorageService } from '@services/storage.service';
import { EventsService } from '@services/events.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	private URL_BASE: string = environment.apiUrl;
	private user: User | undefined;

	constructor(private http: HttpClient, private storageService: StorageService, private eventsService: EventsService) {
		this.user = this.storageService.get('user');

		this.eventsService.subscribe('logout:user', () => {
			this.user = undefined;
		});
	}

	request(endpoint: string, params?: any, merchant?: string, post?: boolean, authKey?: boolean) {
		let url = this.URL_BASE + endpoint;

		let httpParams = new HttpParams();

		if (params)
			for (let p in params) {
				httpParams = httpParams.set(p, params[p]);
			}

		let httpHeaders = new HttpHeaders();

		if (authKey && this.user) {
			httpHeaders = httpHeaders.set('Authorization', this.user?.auth_key);
		}

		if (post) {
			if (merchant) {
				params[merchant] = environment.merchantId;
			}

			return this.http.post(url, params, {
				headers: httpHeaders
			});
		}

		if (merchant) {
			httpParams = httpParams.set(merchant, environment.merchantId);
		}

		return this.http.get(url, {
			headers: httpHeaders,
			params: httpParams
		});
	}
}
