import { Component, Input } from '@angular/core';
import { Category, Merchant, Specialty, User } from '@models/models.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BasicComponent } from '@pages/basic/basic.component';
import { LoginComponent } from '@pages/login/login.component';
import { Router } from '@angular/router';
import { ContactService } from '@services/services.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BasicComponent {
	@Input() categories: Category[] = [];
	@Input() specialties: Specialty[] = [];
	@Input() merchant: Merchant | undefined;
	user: User;
	contactForm: FormGroup;
	contactSent: boolean = false;

	constructor(private formBuilder: FormBuilder, private router: Router, private contactService: ContactService) {
		super();

		this.user = this.storageService.get('user');

		this.contactForm = this.formBuilder.group({
			name: [null, [Validators.required]],
			email: [null, [Validators.required, Validators.email]],
			message: [null, [Validators.required]],
			terms: [null, [Validators.required]]
		});
	}

	openLogin() {
		if (!this.user) {
			this.modalsService.openModal(LoginComponent, true, 'modal-container');
		} else {
			this.router.navigate(['/profile']);
		}
	}

	contact() {
		this.contactService.email(this.contactForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.toastService.showSuccess(resp.message);
				this.contactForm.reset();
				this.contactSent = true;
			}
		});
	}
}
