<h2 class="ob-title">Alterar Dados Pessoais</h2>

<div class="profile-container">
	<div class="row mb-5">
		<div class="col-12 col-md-4 my-2">
			<app-menu-profile></app-menu-profile>
		</div>
		<div class="col-12 col-md-8 my-2 profile-content">
			<form class="form" (submit)="edit()" [formGroup]="userForm">
				<h2>Dados Pessoais</h2>
				<div class="row">
					<!-- Name -->
					<div class="col-12 col-md-12 col-lg-6 mt-3">
						<p class="label">Nome</p>
						<input type="text" formControlName="name" />
					</div>

					<!-- Email -->
					<div class="col-12 col-md-12 col-lg-6 mt-3">
						<p class="label">Email</p>
						<input type="text" formControlName="email" />
					</div>

					<!-- Nif -->
					<div class="col-12 col-md-12 col-lg-6 mt-3">
						<p class="label">Nif</p>
						<input type="number" formControlName="nif" />
					</div>

					<!-- Phone -->
					<div class="col-12 col-md-12 col-lg-6 mt-3">
						<p class="label">Telemóvel</p>
						<input type="number" formControlName="phone" />
					</div>
				</div>

				<button type="submit" [disabled]="userForm.invalid" class="btn center btn-primary mt-3">Guardar</button>
			</form>
		</div>
	</div>
</div>
