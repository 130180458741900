import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from '@app/services/contact.service';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-contact-container',
	templateUrl: './contact-container.component.html',
	styleUrls: ['./contact-container.component.scss']
})
export class ContactContainerComponent extends BasicComponent implements OnInit {
	contactForm: FormGroup;

	constructor(private formBuilder: FormBuilder, private contactService: ContactService) {
		super();

		this.contactForm = this.formBuilder.group({
			name: [null, [Validators.required]],
			email: [null, [Validators.required, Validators.email]],
			subject: [null, [Validators.required]],
			message: [null, [Validators.required]]
		});
	}

	ngOnInit(): void {}

	create() {
		this.contactService.email(this.contactForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.contactForm.reset();
				this.toastService.showSuccess(resp.message);
			} else {
				this.toastService.showError(resp.message);
			}
		});
	}
}
