<div class="login-container">
	<form *ngIf="selectedView == 'login'" class="form" (submit)="login()" [formGroup]="loginForm">
		<h2 class="ob-title">Iniciar Sessão</h2>

		<!-- Email -->
		<input class="mt-3" type="text" (input)="emailChange($event)" placeholder="Email" formControlName="email" />

		<!-- Password -->
		<input class="mt-3" type="password" placeholder="Palavra-Passe" formControlName="password" />

		<button type="submit" [disabled]="loginForm.invalid" class="btn btn-white btn-block mt-3">Login</button>
		<p class="mt-3">ou</p>
		<button class="btn btn-white btn-block mt-3" (click)="selectedView = 'signup'">Registar</button>
		<button class="btn btn-white-outline btn-block mt-3" (click)="selectedView = 'password'">Esqueci a palavra-passe!</button>
	</form>

	<form *ngIf="selectedView == 'password'" class="form" (submit)="recover()" [formGroup]="passwordForm">
		<h2 class="ob-title">Recuperar Palavra-Passe</h2>

		<!-- Email -->
		<input class="mt-3" type="text" (input)="emailChange($event)" placeholder="Email" formControlName="email" />

		<button type="submit" [disabled]="passwordForm.invalid" class="btn btn-white btn-block mt-3">Recuperar</button>
		<p class="mt-3">ou</p>
		<button class="btn btn-white btn-block mt-3" (click)="selectedView = 'login'">Login</button>
	</form>

	<form *ngIf="selectedView == 'signup'" class="form" (submit)="signup()" [formGroup]="signupForm">
		<h2 class="ob-title">Registo</h2>

		<!-- Name -->
		<input class="mt-3" type="text" placeholder="Nome" formControlName="name" />

		<!-- Email -->
		<input class="mt-3" type="text" (input)="emailChange($event)" placeholder="Email" formControlName="email" />

		<!-- Phone -->
		<input class="mt-3" type="text" placeholder="Telemóvel" formControlName="phone" />

		<!-- Password -->
		<input class="mt-3" type="password" placeholder="Palavra-Passe" formControlName="password" />

		<button type="submit" [disabled]="signupForm.invalid" class="btn btn-white btn-block mt-3">Registar</button>
		<p class="mt-3">ou</p>
		<button class="btn btn-white btn-block mt-3" (click)="selectedView = 'login'">Login</button>
	</form>
</div>
