import { Component, OnInit } from '@angular/core';
import { Store } from '@models/models.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppointmentService, StoreService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-appointment-container',
	templateUrl: './appointment-container.component.html',
	styleUrls: ['./appointment-container.component.scss']
})
export class AppointmentContainerComponent extends BasicComponent implements OnInit {
	selectedStore: any;
	stores: Store[] = [];
	specialties: any[] = [];
	startDate = new Date();
	endDate = new Date();
	appointmentForm: FormGroup;
	isDateOk = true;
	isTimeOk = true;

	constructor(private formBuilder: FormBuilder, private storeService: StoreService, private appointmentService: AppointmentService) {
		super();

		this.appointmentForm = this.formBuilder.group({
			store_id: [null, [Validators.required]],
			specialty_id: [null, [Validators.required]],
			date: [null, [Validators.required]],
			time: [null, [Validators.required]],
			name: [null, [Validators.required]],
			phone: [null, [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
			email: [null, [Validators.required, Validators.email]]
		});
	}

	ngOnInit(): void {
		let date = new Date();
		this.startDate.setDate(date.getDate() + 3);
		this.endDate.setDate(date.getDate() + 90);
		this.load();
	}

	load() {
		this.storeService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.stores = resp.data;
				this.selectedStore = this.stores[0];
				this.specialties = this.selectedStore.specialties;
				this.appointmentForm.controls['store_id'].setValue(this.selectedStore.id);
			}
		});
	}

	changeStore(event: any) {
		this.selectedStore = this.stores.find((store: any) => store.id == event.target.value);
		this.specialties = this.selectedStore.specialties;
		this.appointmentForm.controls['store_id'].setValue(this.selectedStore.id);
	}

	validateDate() {
		let data = this.appointmentForm.value;
		const today = new Date();
		const date = new Date(data.date);

		if (date.getTime() < today.getTime()) {
			this.isDateOk = false;
			return;
		}

		const day = date.getDay();
		const schedule = this.selectedStore.schedules[day - 1];

		if (schedule == undefined) {
			this.isDateOk = false;
		} else {
			this.isDateOk = true;
		}
	}

	validateTime() {
		let data = this.appointmentForm.value;
		const d = new Date(data.date);
		const day = d.getDay();
		const schedule = this.selectedStore.schedules[day - 1];

		if (schedule == undefined) {
			this.isTimeOk = false;
		} else {
			this.isDateOk = true;
			let sHour = schedule.start;
			let eHour = schedule.end2 ? schedule.end2 : schedule.end;
			if (data.time < sHour || data.time > eHour) {
				this.isTimeOk = false;
			} else {
				this.isTimeOk = true;
			}
		}
	}

	create() {
		let data = this.appointmentForm.value;
		data['status'] = '1';

		this.appointmentService.create(data).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.appointmentForm.reset();
				this.toastService.showSuccess(resp.message);
			} else {
				this.toastService.showError(resp.message);
			}
		});
	}
}
