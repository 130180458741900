import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BasicComponent } from '@pages/basic/basic.component';
import { UserService } from '@services/services.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BasicComponent {
	loginForm: FormGroup;
	passwordForm: FormGroup;
	signupForm: FormGroup;
	selectedView: string = 'login';

	constructor(private formBuilder: FormBuilder, private userService: UserService) {
		super();

		this.loginForm = this.formBuilder.group({
			email: [null, [Validators.required, Validators.email]],
			password: [null, [Validators.required]]
		});

		this.signupForm = this.formBuilder.group({
			name: [null, [Validators.required]],
			email: [null, [Validators.required, Validators.email]],
			phone: [null, [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
			password: [null, [Validators.required]]
		});

		this.passwordForm = this.formBuilder.group({
			email: [null, [Validators.required, Validators.email]]
		});
	}

	emailChange(event: any) {
		switch (this.selectedView) {
			case 'login':
				this.loginForm.controls['email'].setValue(event.target.value.trim());
				break;
			case 'password':
				this.passwordForm.controls['email'].setValue(event.target.value.trim());
				break;
			default:
				this.signupForm.controls['email'].setValue(event.target.value.trim());
				break;
		}
	}

	login() {
		this.userService.login(this.loginForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.doLogin(resp.data);
			} else {
				this.toastService.showError('Erro a fazer login');
			}
		});
	}

	recover() {
		this.userService.recovery(this.passwordForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.selectedView = 'login';
				this.toastService.showSuccess('Recuperação efetuada com sucesso');
			} else {
				this.toastService.showError('Utilizador inválido');
			}
		});
	}

	signup() {
		let data = this.signupForm.value;
		data['external_id'] = '';
		data['type'] = '0';
		data['status'] = '1';

		this.userService.create(data).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.selectedView = 'login';
				this.toastService.showSuccess('Criado com sucesso');
			} else {
				this.toastService.showError(resp.data);
			}
		});
	}

	doLogin(user: any) {
		this.storageService.set('user', user);
		this.navigateHome();
		this.toastService.showSuccess('Login efetuado com sucesso');
	}
}
