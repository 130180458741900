import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from '@services/news.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { News } from '@models/news.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
	selector: 'app-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.scss']
})
export class EventComponent extends BasicComponent implements OnInit {
	@ViewChild('slick-carousel') slickModal: SlickCarouselComponent;
	event: any;
	news: News[] = [];

	slideConfig: any = {
		autoplay: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		centerMode: false,
		variableWidth: false,
		dots: true,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 775,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	constructor(private newsService: NewsService, private activatedRoute: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe(async (params: any) => {
			this.load(params.id);
		});
	}

	load(id: string) {
		this.newsService.one(id).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.event = resp.data;
				this.loadNews();
			}
		});
	}

	loadNews() {
		this.newsService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.news = resp.data.filter((n: any) => n.id != this.event.id);
			}
		});
	}

	next() {
		this.slickModal.slickNext();
	}

	prev() {
		this.slickModal.slickPrev();
	}
}
