<h2 class="ob-title">Meu Perfil</h2>

<div *ngIf="user" class="profile-container">
	<div class="row mb-5">
		<div class="col-12 col-md-4 my-2">
			<app-menu-profile></app-menu-profile>
		</div>
		<div class="col-12 col-md-4 my-2 profile-content">
			<h2>{{ user.name }}</h2>
			<p>{{ user.email }}</p>
			<p class="m-0"><strong>Saldo</strong></p>
			<p class="big-text">{{ card?.value }}€</p>
			<p class="m-0"><strong>Cartão Cliente</strong></p>
			<p class="big-text">#{{ card?.card_number }}</p>
		</div>

		<div class="col-12 col-md-4 my-2 coupons-content">
			<h2>Cupões</h2>
			<hr />
			<div class="content-order row align-items-center" *ngFor="let coupon of coupons; let i = index">
				<div class="col-12">
					<div class="row align-items-center">
						<div class="col-12 col-md-6 my-2">
							<img class="img center" [src]="coupon.image" alt="" />
						</div>
						<div class="col-12 col-md-6 my-2">
							<h3 class="text-start">Cupão #{{ coupon.number }}</h3>
							<p class="text-start">{{ coupon.description }}</p>
							<p class="text-start">Valor: {{ coupon.amount }}{{ coupon.amount_type }}</p>
							<p class="text-start">Min: {{ coupon.min }}€ Max: {{ coupon.max }}</p>
							<p class="text-start">Expira em: {{ coupon.expires_at }}</p>
						</div>
					</div>
				</div>
				<hr *ngIf="i != coupons.length - 1" />
			</div>
		</div>
	</div>
</div>
