import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class OrderService {
	controller: string = 'order/';

	constructor(private apiService: ApiService) {}

	all() {
		return this.apiService.request(this.controller + 'allorders', null, undefined, false, true);
	}

	one(id: number) {
		return this.apiService.request(this.controller + 'one', { id }, undefined, false, true);
	}

	create(data: any) {
		return this.apiService.request(this.controller + 'new', data, 'merchant_id', true, true);
	}
}
