import { Component } from '@angular/core';
import { ABOUTS, About, DESCRIPTION } from '@models/about.model';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.scss']
})
export class AboutComponent extends BasicComponent {
	description: string = DESCRIPTION;
	abouts: About[] = ABOUTS;

	constructor() {
		super();
	}
}
