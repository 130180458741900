<h2 class="ob-title">Formulário de Contacto</h2>

<div class="row contact-container">
	<div class="col col-12 col-md-6">
		<div class="img-contact" [style.background-image]="'url(assets/images/contact.jpeg)'"></div>
	</div>
	<div class="col col-12 col-md-6">
		<form class="form" (submit)="create()" [formGroup]="contactForm">
			<!-- Name -->
			<div class="mt-3">
				<input type="text" placeholder="Nome" formControlName="name" />
			</div>

			<!-- Email -->
			<div class="mt-3">
				<input type="text" placeholder="Email" formControlName="email" />
			</div>

			<!-- Subject -->
			<div class="mt-3">
				<input type="text" placeholder="Assunto" formControlName="subject" />
			</div>

			<!-- Message -->
			<div class="mt-3">
				<textarea placeholder="Mensagem" formControlName="message" rows="4"></textarea>
			</div>

			<button type="submit" [disabled]="contactForm.invalid" class="btn mt-3 w-50 center btn-white">Enviar</button>
		</form>
	</div>
</div>
