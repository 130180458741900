import { Component, OnInit } from '@angular/core';
import { Product, User } from '@models/models.model';
import { UserService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-favorites',
	templateUrl: './favorites.component.html',
	styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent extends BasicComponent implements OnInit {
	user: User;
	products: Product[] = [];

	constructor(private userService: UserService) {
		super();

		this.user = this.storageService.get('user');

		if (!this.user) {
			this.navigateHome();
		}
	}

	ngOnInit(): void {
		this.load();
	}

	load() {
		this.userService.favorites().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.products = resp.data;
			}
		});
	}
}
