<div class="contacts-container">
	<h2 class="ob-title">Contactos</h2>

	<iframe id="map" width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(%C3%93ptica%20Boavista)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population Estimator map</a></iframe>

	<div class="row mb-5">
		<div class="col-12 col-md-3">
			<h3>Email</h3>
			<p>shop@opticaboavista.com</p>
			<p>Siga-nos nas redes sociais:</p>
			<app-social-links></app-social-links>
		</div>
		<div class="col-12 col-md-3">
			<h3>Telefones</h3>
			<p class="m-0"><b>Megastore</b></p>
			<p class="mb-0">+351 220 969 940</p>
			<p class="small-text">(chamada para rede fixa)</p>
			<p class="m-0"><b>Clínica</b></p>
			<p class="mb-0">+351 220 969 950</p>
			<p class="small-text">(chamada para rede fixa)</p>
		</div>
		<div class="col-12 col-md-3">
			<h3>Telemóveis</h3>
			<p class="m-0"><b>Megastore</b></p>
			<p class="mb-0">+351 916 785 887</p>
			<p class="small-text">(chamada para rede móvel)</p>
			<p class="m-0"><b>Loja Online</b></p>
			<p class="mb-0">+351 912 156 666</p>
			<p class="small-text">(chamada para rede móvel)</p>
		</div>
		<div class="col-12 col-md-3">
			<h3>Moradas</h3>
			<p class="m-0"><b>Megastore</b></p>
			<p>Avenida da Boavista 1003 4100-128 Porto</p>
			<p class="m-0"><b>Clínica</b></p>
			<p>Avenida da Boavista 1003 4100-128 Porto</p>
			<p class="m-0"><b>Parque Privativo</b></p>
			<p>Rua Guerra Junqueiro, Nº640</p>
		</div>
	</div>

	<app-contact-container></app-contact-container>
</div>
