<div class="img-about mt-3" [style.background-image]="'url(assets/images/about.jpg)'"></div>

<h2 class="ob-title">Sobre Nós</h2>

<div class="about-description" [innerHtml]="description"></div>

<div *ngFor="let about of abouts; let i = index">
	<div [ngClass]="i % 2 !== 0 ? 'about-container-inverse' : 'about-container'">
		<img [src]="about.image" />
		<div class="bar"></div>
		<div class="details">
			<h2 [innerHtml]="about.name"></h2>
			<div [innerHtml]="about.resume"></div>
			<a [href]="'/quem-somos/' + about.id" class="btn btn-primary">Ver mais</a>
		</div>
	</div>
</div>
