<div class="container mt-5">
	<div class="row mb-5">
		<div class="col-12 col-md-4 my-2">
			<div class="content-menu">
				<app-menu-profile></app-menu-profile>
			</div>
		</div>
		<div class="col-12 col-md-8 my-2">
			<div class="content-coupon">
				<h2 class="text-start mt-5 mb-2">Cupões</h2>
				<p *ngIf="coupons.length == 0">Não tem nenhum cupão</p>
				<div class="content-order row align-items-center" *ngFor="let coupon of coupons; let i = index">
					<div class="col-12">
						<div class="row align-items-center">
							<div class="col-12 col-md-6 my-2">
								<img class="img center" [src]="coupon.image" alt="" />
							</div>
							<div class="col-12 col-md-6 my-2">
								<h3 class="text-start">Cupão #{{ coupon.number }}</h3>
								<p class="text-start">{{ coupon.description }}</p>
								<p class="text-start">Valor: {{ coupon.amount }}{{ coupon.amount_type }}</p>
								<p class="text-start">Min: {{ coupon.min }}€ Max: {{ coupon.max }}</p>
								<p class="text-start">Expira em: {{ coupon.expires_at }}</p>
							</div>
						</div>
					</div>
					<hr *ngIf="i != coupons.length - 1" />
				</div>
			</div>
		</div>
	</div>
</div>
