<h2 *ngIf="order" class="ob-title">Encomenda: {{ order.name }}</h2>

<div class="order-container">
	<p class="text-start">
		<a role="button" (click)="goBack()"><i class="fas fa-angle-left"></i> Voltar</a>
	</p>
	<div *ngIf="order">
		<div class="row">
			<div class="col-12 col-md-6">
				<p class="m-0"><b>Morada Entrega</b></p>
				<p>
					{{ order.customer.address_comercial }} <br />
					{{ order.customer.postcode_comercial }}, {{ order.customer.city_comercial }}
				</p>
			</div>
			<div class="col-12 col-md-6">
				<p class="m-0"><b>Morada Faturada</b></p>
				<p>
					{{ order.customer.address_fiscal }} <br />
					{{ order.customer.postcode_fiscal }}, {{ order.customer.city_fiscal }}
				</p>
			</div>
		</div>

		<hr />

		<p class="m-0"><b>Detalhes da Encomenda</b></p>
		<p>
			<b>Data:</b> {{ order.date }} <br />
			<b>Estado:</b> {{ order.status }} <br />
			<b>Metodo de Pagamento:</b> {{ order.payment_type }} <br />
			<b>Total Pago:</b> {{ order.total }}
		</p>

		<hr />

		<div class="row align-items-center">
			<div class="col-2">
				<p><b>Artigo</b></p>
			</div>
			<div class="col-6">
				<p><b>Descrição</b></p>
			</div>
			<div class="col-2">
				<p class="text-end"><b>Preço</b></p>
			</div>
			<div class="col-2">
				<p class="text-end"><b>Quant.</b></p>
			</div>
		</div>

		<div class="row align-items-center my-2" *ngFor="let item of order.items">
			<div class="col-2">
				<img class="img center" [src]="item.image" />
			</div>
			<div class="col-6">
				<p>{{ item.name }}</p>
			</div>
			<div class="col-2">
				<p class="text-end">x {{ item.quantity }}</p>
			</div>
			<div class="col-2">
				<p class="text-end">{{ item.price }}€</p>
			</div>
		</div>
	</div>
</div>
