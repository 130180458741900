import { Component, OnInit } from '@angular/core';
import { BasicComponent } from '@pages/basic/basic.component';
import { Specialty } from '@models/models.model';
import { SpecialtyService } from '@services/services.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-specialty',
	templateUrl: './specialty.component.html',
	styleUrls: ['./specialty.component.scss']
})
export class SpecialtyComponent extends BasicComponent implements OnInit {
	id: number;
	specialty: Specialty | undefined;

	constructor(private specialtyService: SpecialtyService, private activatedRoute: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe(async (params: any) => {
			this.load(params.id);
		});
	}

	load(id: number) {
		this.specialtyService.one(id).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.specialty = resp.data;
			}
		});
	}
}
