import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class StoreService {
	controller: string = 'store/';
	constructor(private apiService: ApiService) {}

	all() {
		return this.apiService.request(this.controller + 'all', null, 'id');
	}

	specialty() {
		return this.apiService.request(this.controller + 'specialty');
	}

	count() {
		return this.apiService.request(this.controller + 'count');
	}
}
