import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/user.service';
import { User } from '@models/models.model';
import { BasicComponent } from '@pages/basic/basic.component';

@Component({
	selector: 'app-edit-user',
	templateUrl: './edit-user.component.html',
	styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent extends BasicComponent implements OnInit {
	user: User;
	userForm: FormGroup;

	constructor(private formBuilder: FormBuilder, private userService: UserService) {
		super();

		this.user = this.storageService.get('user');

		if (!this.user) {
			this.navigateHome();
		}

		this.userForm = this.formBuilder.group({
			name: [this.user?.name, [Validators.required]],
			email: [this.user?.email, [Validators.required, Validators.email]],
			phone: [Number(this.user?.phone), [Validators.required, Validators.minLength(9), Validators.maxLength(0)]],
			nif: [Number(this.user?.nif), [Validators.required, Validators.minLength(9), Validators.maxLength(9)]]
		});
	}

	ngOnInit(): void {}

	edit() {
		this.userService.edit(this.userForm.value).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.user = resp.data;
				this.storageService.set('user', this.user);
				this.toastService.showSuccess('Editado com sucesso');
			} else {
				this.toastService.showError('Erro ao editar');
			}
		});
	}
}
