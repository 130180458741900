import { Component, OnInit } from '@angular/core';
import { OrderService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { Order, User } from '@models/models.model';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss']
})
export class OrderComponent extends BasicComponent implements OnInit {
	user: User;
	orderId: number;
	order: Order;

	constructor(private orderService: OrderService, private location: Location, private activatedRoute: ActivatedRoute) {
		super();

		this.user = this.storageService.get('user');

		if (!this.user) {
			this.navigateHome();
		}

		this.activatedRoute.params.subscribe(async (params: any) => {
			this.orderId = params.id;
			this.load();
		});
	}

	ngOnInit(): void {}

	load() {
		this.orderService.one(this.orderId).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.order = resp.data;
			}
		});
	}

	goBack() {
		this.location.back();
	}
}
