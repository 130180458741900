import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { Coupon, User } from '@models/models.model';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BasicComponent implements OnInit {
	user: User;
	card: any;
	coupons: Coupon[] = [];

	constructor(private userService: UserService) {
		super();

		this.user = this.storageService.get('user');

		if (!this.user) {
			this.navigateHome();
		}

		this.loadLoyaltyCard();
	}

	ngOnInit(): void {}

	loadLoyaltyCard() {
		this.userService.loyaltycards().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				if (resp.data.length > 0) {
					this.card = resp.data[0];
					this.loadCoupons(this.card.id);
				}
			}
		});
	}

	loadCoupons(id: number) {
		this.userService.viewcard(id).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.coupons = resp.data;
			}
		});
	}
}
