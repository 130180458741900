import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'schedules'
})
export class SchedulesPipe implements PipeTransform {
	transform(value: any, ...args: any[]): unknown {
		const week = this.formatWeek(value);

		let result = '';

		week.forEach((item: any) => {
			if (item.first === item.last) {
				result += `${item.first}, ${item.start} - ${item.end}`;

				if (item.start2 && item.end2) {
					result += ` / ${item.start2} - ${item.end2}`;
				}

				result += '<br />';
			} else {
				result += `${item.first} a ${item.last}, ${item.start} - ${item.end}<br />`;
			}
		});

		return result;
	}

	formatWeek(value: any) {
		let data: any = [];
		value.forEach((day: any) => {
			let key = `${day.start}/${day.end}`;

			let index = data.findIndex((item: any) => item.key === key);

			if (index != -1) {
				data[index].last = day.day.substring(0, 3);
			} else {
				data.push({ key: key, start: day.start, end: day.end, start2: day.start2, end2: day.end2, first: day.day.substring(0, 3), last: day.day.substring(0, 3) });
			}
		});

		return data;
	}
}
