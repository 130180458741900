import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import { Coupon, User } from '@models/models.model';

@Component({
	selector: 'app-coupons',
	templateUrl: './coupons.component.html',
	styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent extends BasicComponent implements OnInit {
	user: User;
	loyaltyCard: any;
	coupons: Coupon[] = [];

	constructor(private userService: UserService) {
		super();

		this.user = this.storageService.get('user');

		if (!this.user) {
			this.navigateHome();
		}
	}

	ngOnInit(): void {
		this.loadLoyaltyCard();
	}

	loadLoyaltyCard() {
		this.userService.loyaltycards().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.loyaltyCard = resp.data;
				if (this.loyaltyCard.length > 0) {
					this.loadCoupons(this.loyaltyCard[0].id);
				}
			}
		});
	}

	loadCoupons(id: number) {
		this.userService.viewcard(id).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.coupons = resp.data;
			}
		});
	}
}
