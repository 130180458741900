import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Product, User } from '@models/models.model';
import { BasicComponent } from '@pages/basic/basic.component';
import { CartService, ProductService } from '@services/services.service';

@Component({
	selector: 'app-product-view',
	templateUrl: './product-view.component.html',
	styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent extends BasicComponent implements OnInit {
	user: User;
	@Input() product: Product;
	@Output() onOpenProduct = new EventEmitter<boolean>();

	constructor(private router: Router, private productService: ProductService, private cartService: CartService) {
		super();

		this.user = this.storageService.get('user');
	}

	ngOnInit(): void {}

	openProduct(product: Product) {
		this.onOpenProduct.emit(true);
		this.router.navigate(this.getPath('/produto', product));
	}

	addProduct(event: any, product: Product) {
		event.stopPropagation();
		if (product.has_grade_lens) {
			this.openProduct(product);
		} else {
			let discount = product.discount ? product.price * product.discount * 0.01 : 0;

			let p: any = {
				product_id: product.id,
				image: product.image,
				name: product.name,
				price: product.price - discount,
				discount: discount,
				original_price: this.product.price,
				quantity: 1
			};
			this.cartService.addProduct(p);
		}
	}

	addFavorite(event: any) {
		event.stopPropagation();
		this.productService.favorite(this.product.id).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.product.favorite = !this.product.favorite;
				this.toastService.showSuccess('Adicionado/Removido com sucesso');
			}
		});
	}
}
