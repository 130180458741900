<h2 class="ob-title">Favoritos</h2>

<div class="fav-container">
	<div *ngIf="products.length == 0" class="mt-4">
		<p class="text-center">
			<small class="text-muted">Sem favoritos!</small>
		</p>
	</div>
	<div class="product-container">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div *ngFor="let product of products" class="col-12 col-sm-6 col-md-6 col-lg-3">
						<app-product-view [product]="product"></app-product-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
