<h2 class="ob-title">Fidelização</h2>

<div class="profile-container">
	<div class="row mb-5">
		<div class="col-12 col-md-4 my-2">
			<app-menu-profile></app-menu-profile>
		</div>
		<div class="col-12 col-md-8 my-2 profile-content">
			<h2 class="mt-3">Dados Cartão</h2>
			<div *ngIf="card" class="col-12 col-md-4 my-2">
				<p class="m-0"><b>Saldo:</b> {{ card.value }}€</p>
				<p class="m-0"><b>Cartão Cliente:</b> #{{ card.card_number }}</p>
			</div>

			<h2 class="mt-3">Movimentos</h2>
			<hr />
			<div class="row">
				<div class="col-3">
					<b>Loja</b>
				</div>
				<div class="col-2">
					<b>Valor</b>
				</div>
				<div class="col-2">
					<b>Cashback</b>
				</div>
				<div class="col-2">
					<b>Saldo</b>
				</div>
				<div class="col-3"><b>Data</b></div>
			</div>
			<hr />
			<div class="row" *ngFor="let transaction of transactions">
				<div class="col-3">{{ transaction.store_name }}</div>
				<div class="col-2">{{ transaction.amount }}</div>
				<div class="col-2">{{ transaction.total_cashback }}</div>
				<div class="col-2">{{ transaction.used_amount }}</div>
				<div class="col-3">{{ transaction.created_at }}</div>
			</div>
		</div>
	</div>
</div>
