<div class="container-search my-5">
	<form class="form" [formGroup]="searchForm">
		<input (ngModelChange)="search($event)" class="input px-2" type="text" formControlName="search" placeholder="Pesquisar" />
	</form>

	<p class="my-2">Experimente pesquisar "óculos de sol vogue"</p>

	<div role="button" (click)="dismiss()" class="btn-close col-2">
		<i class="fas fa-close fa-3x"></i>
	</div>
</div>

<div class="container">
	<div class="content-products">
		<app-container-products *ngIf="products.length > 0" (openProduct)="dismiss()" [seeAllProduct]="true" [products]="products"></app-container-products>
	</div>
</div>
