<div class="event-container">
	<img class="img-event mt-3" [src]="event?.image_path" />

	<h2 class="ob-title">{{ event?.title }}</h2>
	<div class="text-justify event-description" [innerHtml]="event?.content"></div>

	<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
		<div ngxSlickItem *ngFor="let n of news" class="slide">
			<a class="card" [href]="getPathTitleAsString('/novidade', n)">
				<img [src]="n.image_path" class="card-img-top" />
				<div class="card-body">
					<h5 class="card-title">{{ n.title }}</h5>
					<p>{{ n.subtitle }}</p>
				</div>
			</a>
		</div>
	</ngx-slick-carousel>
</div>
