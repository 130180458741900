import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class AddressService {
	controller: string = 'address/';

	constructor(private apiService: ApiService) {}

	all() {
		return this.apiService.request(this.controller + 'all', null, undefined, false, true);
	}

	create(data: any) {
		return this.apiService.request(this.controller + 'create', data, undefined, true, true);
	}

	delete(id: number) {
		return this.apiService.request(this.controller + 'delete', { id }, undefined, false, true);
	}
}
