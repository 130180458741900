import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
	providedIn: 'root'
})
export class AppointmentService {
	controller: string = 'appointment/';

	constructor(private apiService: ApiService) {}

	create(data: any) {
		return this.apiService.request(this.controller + 'create', data, 'merchant_id', true);
	}
}
