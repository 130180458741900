import { Product } from '@models/product.model';

export class Cart {
	total: number;
	quant: number;
	discount: number;
	products: any[];

	constructor() {
		this.total = 0;
		this.quant = 0;
		this.discount = 0;
		this.products = [];
	}
}
